import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  Row,
  Typography,
  Switch,
  Checkbox,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  postAwardSchemes,
  clearSuccessCreate,
  clearError,
  setOpenAwardSchemesCreate,
} from "../../store/awardSchemes";
import {
  getSuccessCreate,
  getError,
  getAwardSchemesLoadingCreate,
  getOpenAwardSchemesCreateSelector,
} from "../../store/awardSchemes/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import { throwFormError } from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";
import { LoaderModal } from "../LoaderModal";
import styles from "./styles.module.css";

export const CreateAwardSchemesModal = () => {
  const [formCreateAwardSchemes] = Form.useForm();
  const dispatch = useDispatch();
  const openSuccessCreate = useSelector(getSuccessCreate);
  const openAwardSchemesCreate = useSelector(getOpenAwardSchemesCreateSelector);
  const [width, setWidth] = useState(window.innerWidth);
  const [turnoverBonus, setTurnoverBonus] = useState(false);
  const [premiumTurnover, setPremiumTurnover] = useState(false);
  const [warrantyAlterations, setWarrantyAlterations] = useState(false);
  const [reductionBonusPeriod, setReductionBonusPeriod] = useState(false);
  const studio = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoader = useSelector(getAwardSchemesLoadingCreate);

  const onPremiumTurnoverChange = (e) => {
    setPremiumTurnover(e.target.checked);
  };

  const onWarrantyAlterationsChange = (e) => {
    setWarrantyAlterations(e.target.checked);
  };

  const onReductionBonusPeriodChange = (e) => {
    setReductionBonusPeriod(e.target.checked);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <Drawer
      styles={{
        body: { padding: 0 },
      }}
      open={openAwardSchemesCreate}
      onCancel={() => {
        dispatch(clearError());
        dispatch(clearSuccessCreate());
        dispatch(setOpenAwardSchemesCreate());
      }}
      onClose={() => {
        dispatch(clearError());
        dispatch(clearSuccessCreate());
        dispatch(setOpenAwardSchemesCreate());
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Создание схемы премий
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      {isLoader && <LoaderModal />}
      {openSuccessCreate ? (
        <SuccessModalSide
          close={() => {
            dispatch(clearError());
            dispatch(clearSuccessCreate());
            dispatch(setOpenAwardSchemesCreate());
          }}
          title="Схема премий успешно добавлена!"
        />
      ) : (
        <div
          style={{
            padding: width > 640 ? "0 64px 20px" : "0 24px 20px",
          }}
        >
          <Form
            style={{ marginTop: "20px" }}
            layout="vertical"
            form={formCreateAwardSchemes}
            onFinish={(data) => {
              data.studio = studio?.id;
              data.add_goods_in_rev = warrantyAlterations;
              data.bonus_by_revenue = turnoverBonus;
              data.corrs_from_revenue = premiumTurnover;
              data.reduce_revenue_with_master_works = reductionBonusPeriod;

              dispatch(
                postAwardSchemes({
                  studio_y_id: studio?.y_id,
                  schemes: data,
                })
              ).then((state) => {
                if (state?.payload?.errors) {
                  throwFormError(formCreateAwardSchemes, state?.payload);
                }
              });
            }}
          >
            {error && displayErrors(error)}
            <Form.Item name="name" label="Название схемы">
              <TextArea
                placeholder="Напишите название схемы"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                style={{ borderRadius: 2 }}
              />
            </Form.Item>

            <Form.Item className={styles.switch} name="bonus_by_revenue">
              <Switch checked={turnoverBonus} onChange={setTurnoverBonus} />
              <span className={styles.text}>Бонус за оборот</span>
            </Form.Item>

            {turnoverBonus && (
              <>
                <Form.Item
                  name="porog"
                  label="Порог оборота"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: "Сумма не может быть меньше 0",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Введите порог оборота"
                    style={{ borderRadius: 2, width: "100%" }}
                  />
                </Form.Item>

                <Form.Item className={styles.switch} name="corrs_from_revenue">
                  <Checkbox
                    checked={premiumTurnover}
                    onChange={onPremiumTurnoverChange}
                  />
                  <span className={styles.text}>
                    Вычет гарантийных переделок из оборота
                  </span>
                </Form.Item>

                <Form.Item className={styles.switch} name="add_goods_in_rev">
                  <Checkbox
                    checked={warrantyAlterations}
                    onChange={onWarrantyAlterationsChange}
                  />
                  <span className={styles.text}>
                    Добавлять проданные товары в премиальный оборот
                  </span>
                </Form.Item>

                <Form.Item
                  name="bonus_persent_from_revenue"
                  label="Процент от оборота"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: "Сумма не может быть меньше 0",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Введите процент от оборота"
                    style={{ borderRadius: 2, width: "100%" }}
                  />
                </Form.Item>

                <Form.Item
                  name="bonus_fix_from_revenue"
                  label="Фиксированная сумма"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: "Сумма не может быть меньше 0",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Введите фиксированную сумму"
                    style={{ borderRadius: 2, width: "100%" }}
                  />
                </Form.Item>

                <Form.Item
                  className={styles.switch}
                  name="reduce_revenue_with_master_works"
                >
                  <Checkbox
                    checked={reductionBonusPeriod}
                    onChange={onReductionBonusPeriodChange}
                  />
                  <span className={styles.text}>
                    Уменьшение премиального срока за срок работы
                  </span>
                </Form.Item>

                {reductionBonusPeriod && (
                  <>
                    <Form.Item
                      name="reducing_revenue"
                      label="Уменьшать порог на"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          message: "Сумма не может быть меньше 0",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Введите уменьшение порога"
                        style={{ borderRadius: 2, width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="reducing_revenue_period"
                      label="Период уменьшения"
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          message: "Сумма не может быть меньше 0",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Введите период уменьшения"
                        style={{ borderRadius: 2, width: "100%" }}
                      />
                    </Form.Item>
                  </>
                )}
              </>
            )}

            <Row gutter={24}>
              <Col span={12}>
                <Button
                  style={{ width: "100%", borderRadius: 2 }}
                  onClick={() => {
                    dispatch(setOpenAwardSchemesCreate());
                    dispatch(clearSuccessCreate());
                    dispatch(clearError());
                  }}
                >
                  Отмена
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#76CEC9",
                  }}
                  htmlType="submit"
                  type="primary"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
