import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Input, Table, Spin, Space } from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  convertToLocalDate,
  convertDateToISO861,
  formatDateString,
  reversFormatDateString,
} from "../../utils/utils";
import { getFirstAndLastDayOfPreviousMonth } from "../../utils/getFirstAndLastDayOfPreviousMonth";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData, getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getCalculation,
  postCalculation,
  clearError,
  clearErrorCreate,
  clearSuccessCreate,
  setCalculationSubmitted,
} from "../../store/calculation";
import {
  getCalculationData,
  getOpenCalculationSelector,
  getCalculationLoading,
  getError,
  getSuccess,
  getSuccessCreate,
  getSubmittedCalculationSelector,
} from "../../store/calculation/selectors";
import displayErrors from "../../utils/displayErrors";
import { CalculationDetailModal } from "../../components/CalculationDetailModal";
import { columns } from "./utils";

export const CalculationPage = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getCalculationLoading);
  const errorStudio = useSelector(getErrorStudio);
  const calculationData = useSelector(getCalculationData);
  const isOpenCalculation = useSelector(getOpenCalculationSelector);
  const isSubmittedCalculation = useSelector(getSubmittedCalculationSelector);
  const [width, setWidth] = useState(window.innerWidth);
  const [search, setSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const { firstDay, lastDay } = getFirstAndLastDayOfPreviousMonth();
  const formatDate = (date) => convertToLocalDate(date).split(" ")[0];
  const [dateAfterFilter, setDateAfterFilter] = useState(formatDate(firstDay));
  const [dateBeforeFilter, setDateBeforeFilter] = useState(formatDate(lastDay));
  const [openDate, setOpenDate] = useState(false);
  const [openCalculationDetail, setOpenCalculationDetail] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(
    `${formatDate(firstDay)} - ${formatDate(lastDay)}`
  );
  const openInfo = useSelector(getOpenInfoSelector);
  const success = useSelector(getSuccess);
  const successCreate = useSelector(getSuccessCreate);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [sum, setSum] = useState(0);
  const [salary, setSalary] = useState(0);
  const [penalty, setPenalty] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [bounty, setBounty] = useState(0);
  const [prepayment, setPrepayment] = useState(0);
  const [officialSalary, setOfficialSalary] = useState(0);
  const [payments, setPayments] = useState(0);
  const [ordering, setOrdering] = useState({
    y_name: null,
  });

  const getOrderBy = () => {
    let orderBy = "";

    Object.keys(ordering).forEach((key) => {
      if (ordering[key] === "ascend") orderBy = key;
      if (ordering[key] === "descend") orderBy = "-" + key;
    });

    return orderBy;
  };

  const handleSort = (field) => {
    let tempOrdering = {
      y_name: null,
    };

    switch (ordering[field]) {
      case "ascend":
        tempOrdering[field] = "descend";
        break;
      case "descend":
        tempOrdering[field] = null;
        break;
      default:
        tempOrdering[field] = "ascend";
    }

    setOrdering(tempOrdering);
  };

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const fetchData = () => {
    let filters = {};

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    dispatch(clearErrorStudio());
    dispatch(clearError());
    dispatch(clearErrorCreate());
    if (dateAfterFilter && dateBeforeFilter) {
      dispatch(
        getCalculation({
          studio_y_id:
            JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
            studioData?.y_id,
          filters: filters,
          filtersArray: filtersArray,
          ordering: getOrderBy(),
        })
      );
    }
  };

  // const clearFilters = () => {
  //   setFileSearch("");
  //   setViewDataFilter(null);
  //   setDateBeforeFilter(null);
  //   setDateAfterFilter(null);
  //   setFiltersArray([]);
  // };

  useEffect(() => {
    if (isSubmittedCalculation && checkedRows?.length > 0) {
      const payments = checkedRows.map((item) => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0];
        return {
          paid: item?.total_salary,
          period_start_date: reversFormatDateString(dateAfterFilter),
          period_end_date: reversFormatDateString(dateBeforeFilter),
          pay_date_time: formattedDate,
          related_corrections: item?.related_corrections,
          master: item?.master?.id,
          studio: item?.master?.studio?.id,
        };
      });

      dispatch(
        postCalculation({
          studio_y_id: studioData?.y_id,
          payments: payments,
        })
      );

      setCheckedRows([]);
      dispatch(clearSuccessCreate());
      dispatch(setCalculationSubmitted(false));
    }
  }, [isSubmittedCalculation]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    // dateAfterFilter,
    // dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
    ordering,
  ]);

  useEffect(() => {
    const calculateSums = (rows) => {
      return rows.reduce(
        (acc, row) => {
          acc.total_salary += row.total_salary;
          acc.salary += row.salary;
          acc.total_penalty += row.total_penalty;
          acc.total_bonus += row.total_bonus;
          acc.total_bounty += row.total_bounty;
          acc.total_prepayment += row.total_prepayment;
          acc.total_official_salary += row.total_official_salary;
          if (Array.isArray(row.payments)) {
            acc.payments += row.payments.reduce((sum, payment) => {
              return sum + (payment.paid || 0);
            }, 0);
          }
          return acc;
        },
        {
          total_salary: 0,
          salary: 0,
          total_penalty: 0,
          total_bonus: 0,
          total_bounty: 0,
          total_prepayment: 0,
          total_official_salary: 0,
          payments: 0,
        }
      );
    };

    const updateSums = (sums) => {
      setSum(sums.total_salary.toLocaleString("ru-RU"));
      setSalary(sums.salary.toLocaleString("ru-RU"));
      setPenalty(sums.total_penalty.toLocaleString("ru-RU"));
      setBonus(sums.total_bonus.toLocaleString("ru-RU"));
      setBounty(sums.total_bounty.toLocaleString("ru-RU"));
      setPrepayment(sums.total_prepayment.toLocaleString("ru-RU"));
      setOfficialSalary(sums.total_official_salary.toLocaleString("ru-RU"));
      setPayments(sums.payments.toLocaleString("ru-RU"));
    };

    if (isOpenCalculation) {
      if (checkedRows?.length > 0) {
        const sums = calculateSums(checkedRows);
        updateSums(sums);
      } else {
        updateSums({
          total_salary: 0,
          salary: 0,
          total_penalty: 0,
          total_bonus: 0,
          total_bounty: 0,
          total_prepayment: 0,
          total_official_salary: 0,
          payments: 0,
        });
      }
    } else if (calculationData) {
      const aggregatedData = calculateSums(calculationData);
      updateSums(aggregatedData);
    } else {
      updateSums({
        total_salary: 0,
        salary: 0,
        total_penalty: 0,
        total_bonus: 0,
        total_bounty: 0,
        total_prepayment: 0,
        total_official_salary: 0,
        payments: 0,
      });
    }
  }, [calculationData, isOpenCalculation, checkedRows]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            setViewDataFilter={setViewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            setDateAfterFilter={setDateAfterFilter}
            setDateBeforeFilter={setDateBeforeFilter}
            fetchData={fetchData}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={search}
                placeholder="Поиск по имени мастера"
                suffix={
                  <CloseOutlined
                    style={{
                      color: search ? "red" : "black",
                    }}
                    onClick={() => {
                      setSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          {/* {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )} */}
        </div>
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {calculationData?.length || 0} из{" "}
        {calculationData?.length || 0}
      </div>
      <div
        style={{
          maxHeight: isOpenCalculation
            ? "calc(100vh - 270px)"
            : "calc(100vh - 225px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns({
            isOpenCalculation,
            calculationData,
            isCheckedAll,
            setIsCheckedAll,
            setCheckedRows,
            checkedRows,
            formatDateString,
            ordering,
          })}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={calculationData || []}
          onRow={(record) => {
            if (isOpenCalculation) {
              return;
            } else {
              return {
                onClick: (evt) => {
                  evt.stopPropagation();
                  if (evt.target.type !== "checkbox") {
                    setOpenCalculationDetail({
                      open: true,
                      record: record,
                    });
                  }
                },
              };
            }
          }}
          locale={{
            triggerDesc: "сортировать от Я до А",
            triggerAsc: "сортировать от А до Я",
            cancelSort: "сбросить",
          }}
          onHeaderRow={() => {
            return {
              onClick: (e) => {
                switch (e.target.innerText) {
                  case "MАСТЕР":
                    handleSort("y_name");
                    break;
                }
              },
            };
          }}
          footer={() => (
            <tr className="ant-table-row">
              {/* <td className="ant-table-cell" style={{ width: "27%" }}></td> */}
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Зарплата по Yclients:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {salary} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Штраф:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {penalty} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Бонус:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {bonus} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Премия:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {bounty} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Выплата до зарплаты:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {prepayment} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Официальная зарплата:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {officialSalary} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Выплаты:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {payments} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  {isOpenCalculation ? "Выбрано" : "Итого"}:
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      alignSelf: "center",
                    }}
                  >
                    {sum}
                  </span>{" "}
                  р.
                </div>
              </td>
            </tr>
          )}
        />
      </div>
      {openCalculationDetail && (
        <CalculationDetailModal
          openDetails={openCalculationDetail}
          setOpenDetails={setOpenCalculationDetail}
          viewDataFilter={viewDataFilter}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по расчетам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
