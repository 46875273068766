import { useEffect, useState, useRef } from "react";
import { Table, Spin, Col, Row, Button } from "antd";
import { UserModal } from "../../components/UserModal";
import { ModalComponent } from "../../components/ModalComponent";
// import displayErrors from "../../utils/displayErrors";
import { getStudioData } from "../../store/studios/selectors";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsComplaints,
  clearSuccessDelete,
  deleteUserManagement,
} from "../../store/userManagement";
import {
  getUserManagementData,
  getOpenUserManagementSelector,
  getUserManagementLoading,
  getSuccessDelete,
  getSuccessPatch,
} from "../../store/userManagement/selectors";
import { columns } from "./utils";

export const UserManagement = () => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [filtersArray, setFiltersArray] = useState([]);
  // const [needToLoad, setNeedToLoad] = useState(false);
  const [openUserManagementDelete, setOpenUserManagementDelete] =
    useState(false);
  const studioData = useSelector(getStudioData);
  const userManagementData = useSelector(getUserManagementData);
  const openUserManagement = useSelector(getOpenUserManagementSelector);
  const patchSuccess = useSelector(getSuccessPatch);
  const successDelete = useSelector(getSuccessDelete);
  // const nextPage = useSelector(getUserNextPage);
  const isLoading = useSelector(getUserManagementLoading);
  const lastItem = useRef();
  // const observer = useRef();

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    // if (dateAfterFilter || dateBeforeFilter) {
    //   filters = {
    //     start_date: convertDateToISO861(dateAfterFilter),
    //     end_date: convertDateToISO861(dateBeforeFilter),
    //   };
    // }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   limitValue = Number(urlParams.get("limit")) + 25;
    // }

    dispatch(
      getSettingsComplaints({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
      })
    );
  };

  // useEffect(() => {
  //   (async () => {
  //     if (needToLoad && nextPage) {
  //       fetchData(true);
  //       setNeedToLoad(false);
  //     }
  //   })();
  // }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    // dateAfterFilter,
    // dateBeforeFilter,
    filtersArray,
    studioData,
    successDelete,
    patchSuccess,
    // successCreate,
    // successEdit,
  ]);

  // useEffect(() => {
  //   if (isLoadingFails) return;

  //   if (observer?.current) observer?.current.disconnect();

  //   let callback = function (
  //     entries
  //     //observer
  //   ) {
  //     if (
  //       entries[0].isIntersecting &&
  //       nextPage &&
  //       failsData?.data?.results?.length > 0
  //     ) {
  //       setNeedToLoad(true);
  //     }
  //   };
  //   observer.current = new IntersectionObserver(callback);
  //   if (lastItem?.current instanceof Element) {
  //     observer.current.observe(lastItem.current);
  //   }
  // }, [isLoadingFails]);

  return (
    <>
      <p className={styles.text}>
        Список пользователей, которые имеют доступ к просмотру данных студии в
        сети
      </p>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
        }}
      >
        Показано{" "}
        {userManagementData?.flatMap((item) => item?.users_with_access)
          ?.length || 0}{" "}
        из{" "}
        {userManagementData?.flatMap((item) => item?.users_with_access)
          ?.length || 0}
      </div>
      <div
        style={{
          maxHeight: "calc(100vh - 200px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {error && displayErrors(error)} */}
        <Table
          columns={columns({ setOpenUserManagementDelete })}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={
            userManagementData?.flatMap((item) => item?.users_with_access) || []
          }
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      {openUserManagement && (
        <UserModal isModalOpen={openUserManagement} data={userManagementData} />
      )}
      {openUserManagementDelete.open && (
        <ModalComponent
          open={openUserManagementDelete.open}
          title={`Вы уверены, что хотите удалить этого пользователя '${openUserManagementDelete?.data?.y_name}'?`}
          body={
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                  }}
                  onClick={() => setOpenUserManagementDelete(false)}
                >
                  Не удалять
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                    background: "var(--Primary-1-normal, #76CEC9)",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      deleteUserManagement({
                        studio_y_id: studioData?.y_id,
                        id: userManagementData[0]?.id,
                        user_id: openUserManagementDelete?.data?.id,
                      })
                    ).then(() => {
                      dispatch(clearSuccessDelete());
                      setOpenUserManagementDelete(false);
                    });
                  }}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            setOpenUserManagementDelete(false);
          }}
        />
      )}
    </>
  );
};
