import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const getAwardSchemesNetwork = createAsyncThunk(
  "getAwardSchemesNetwork",
  async ({ network_y_id, pagination }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_bonus_schemes/network/${network_y_id}/schemes/`
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const postAwardSchemesNetwork = createAsyncThunk(
  "postAwardSchemesNetwork",
  async ({ network_y_id, schemes }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/network_bonus_schemes/network/${network_y_id}/schemes/`,
        schemes
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const patchAwardSchemesNetwork = createAsyncThunk(
  "patchAwardSchemesNetwork",
  async ({ studio_y_id, id, schemes }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/studio_bonus_schemes/studio/${studio_y_id}/schemes/${id}/`,
        schemes
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAwardSchemes = createAsyncThunk(
  "deleteAwardSchemes",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_bonus_schemes/studio/${studio_y_id}/schemes/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  awardSchemesNetwork: null,
  openAwardSchemesCreate: false,
  nextPage: null,
  success: {
    success: false,
    create: false,
    edit: false,
    delete: false,
  },
  errors: null,
  loaders: {
    common: false,
    create: false,
    edit: false,
    delete: false,
  },
};

const awardSchemesNetworkSlice = createSlice({
  name: "awardSchemesNetworkSlice",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    setOpenAwardSchemesCreate: (state) => {
      state.openAwardSchemesCreate = !state.openAwardSchemesCreate;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
    clearSuccessDelete: (state) => {
      state.success.delete = false;
    },
  },

  extraReducers: (builder) => {
    // getAwardSchemesNetwork
    builder.addCase(getAwardSchemesNetwork.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(getAwardSchemesNetwork.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.awardSchemesNetwork = payload;
    });
    builder.addCase(getAwardSchemesNetwork.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload?.data;
    });
    // postAwardSchemesNetwork
    builder.addCase(postAwardSchemesNetwork.pending, (state) => {
      state.loaders.create = true;
      state.errors = null;
    });
    builder.addCase(postAwardSchemesNetwork.fulfilled, (state) => {
      state.loaders.create = false;
      state.success.create = true;
    });
    builder.addCase(postAwardSchemesNetwork.rejected, (state, action) => {
      state.loaders.create = false;
      state.errors = action?.payload?.data;
    });
    // patchAwardSchemesNetwork
    builder.addCase(patchAwardSchemesNetwork.pending, (state) => {
      state.loaders.edit = true;
      state.errors = null;
    });
    builder.addCase(patchAwardSchemesNetwork.fulfilled, (state) => {
      state.loaders.edit = false;
      state.success.edit = true;
    });
    builder.addCase(patchAwardSchemesNetwork.rejected, (state, action) => {
      state.loaders.edit = false;
      state.errors = action?.payload;
    });
    // deleteAwardSchemes
    builder.addCase(deleteAwardSchemes.pending, (state) => {
      state.loaders.delete = true;
      state.errors = null;
    });
    builder.addCase(deleteAwardSchemes.fulfilled, (state) => {
      state.loaders.delete = false;
      state.success.delete = true;
    });
    builder.addCase(deleteAwardSchemes.rejected, (state, action) => {
      state.loaders.delete = false;
      state.errors = action?.payload;
    });
  },
});
export const {
  clearError,
  clearSuccess,
  setOpenAwardSchemesCreate,
  clearSuccessCreate,
  clearSuccessEdit,
  clearSuccessDelete,
} = awardSchemesNetworkSlice.actions;
export default awardSchemesNetworkSlice.reducer;
