import React from "react";
import { Drawer, Typography, Avatar, Space } from "antd";
import { formatDateString, convertToLocalDate } from "../../utils/utils";

export const CalculationDetailModal = ({
  openDetails,
  setOpenDetails,
  viewDataFilter,
}) => {
  return (
    <Drawer
      open={openDetails?.open}
      onCancel={() => {
        setOpenDetails({ open: false });
      }}
      onClose={() => {
        setOpenDetails({ open: false });
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Детализация расчета
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      <div
        style={{
          display: "flex",
          gap: 45,
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          <Avatar
            style={{ width: 25, minWidth: 25, height: 25 }}
            src={openDetails?.record?.master?.y_avatar}
          />
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ textWrap: "nowrap" }}>
              {openDetails?.record?.master?.y_name}
            </span>
            <span
              style={{
                color: "#8B8B8B",
                fontSize: 12,
                lineHeight: "14.52px",
                textWrap: "nowrap",
              }}
            >
              {openDetails?.record?.master?.y_specialization}
            </span>
          </Space>
        </div>
        <span style={{ fontSize: 16, fontWeight: 700 }}>
          {openDetails?.record?.y_client_salary_data?.salary?.toLocaleString(
            "ru-RU"
          )}{" "}
          р.
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          marginBottom: 22,
        }}
      >
        <p
          style={{ fontSize: 16, fontWeight: 700, margin: 0, paddingLeft: 18 }}
        >
          Зарплата по YClients:
        </p>
        <p style={{ fontSize: 12, margin: 0, paddingLeft: 18 }}>
          {viewDataFilter}
        </p>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            backgroundColor: "#4D7CD633",
            padding: "22px 0 22px 22px",
            borderRadius: 5,
            margin: 0,
          }}
        >
          {openDetails?.record?.salary?.toLocaleString("ru-RU")} р.
        </p>
      </div>
      {openDetails?.record?.penalty?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            marginBottom: 22,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 700,
              margin: 0,
              paddingLeft: 18,
            }}
          >
            Штрафы:
          </p>
          {openDetails?.record?.penalty?.map((item) => {
            return (
              <>
                <p style={{ fontSize: 12, margin: 0, paddingLeft: 18 }}>
                  {convertToLocalDate(item?.created_at)}
                </p>
                <div
                  style={{
                    backgroundColor: "#DA7A7A33",
                    padding: "22px 0 22px 22px",
                    borderRadius: 5,
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      margin: 0,
                    }}
                  >
                    {item?.cost?.toLocaleString("ru-RU")} р.
                  </p>
                  <p>{item?.comment}</p>
                </div>
              </>
            );
          })}
        </div>
      )}
      {openDetails?.record?.bonus?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            marginBottom: 46,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 700,
              margin: 0,
              paddingLeft: 18,
            }}
          >
            Бонус:
          </p>
          {openDetails?.record?.bonus?.map((item) => {
            return (
              <>
                <p style={{ fontSize: 12, margin: 0, paddingLeft: 18 }}>
                  {convertToLocalDate(item?.created_at)}
                </p>
                <div
                  style={{
                    backgroundColor: "#7ADA9D33",
                    padding: "22px 0 22px 22px",
                    borderRadius: 5,
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      margin: 0,
                    }}
                  >
                    {item?.cost?.toLocaleString("ru-RU")} р.
                  </p>
                </div>
              </>
            );
          })}
        </div>
      )}
      {openDetails?.record?.payments?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            marginBottom: 22,
          }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 700,
              margin: 0,
              paddingLeft: 18,
            }}
          >
            Выплаты:
          </p>
          {openDetails?.record?.payments?.map((item) => {
            return (
              <>
                <div
                  style={{
                    backgroundColor: "#DA7A7A33",
                    padding: "22px 0 22px 22px",
                    borderRadius: 5,
                    display: "flex",
                    gap: 50,
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      margin: 0,
                    }}
                  >
                    {item?.paid?.toLocaleString("ru-RU")} р.
                  </p>
                  <p style={{ fontSize: 16, margin: 0 }}>
                    {formatDateString(item?.period_start_date)} -{" "}
                    {formatDateString(item?.period_end_date)}
                  </p>
                </div>
              </>
            );
          })}
        </div>
      )}
    </Drawer>
  );
};
