export const getFirstAndLastDayOfCurrentMonth = () => {
  const currentDate = new Date();

  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const lastDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  return {
    firstDay: firstDayOfCurrentMonth,
    lastDay: lastDayOfCurrentMonth,
  };
};
