export const getAwardSchemesData = ({ awardSchemesMaster }) =>
  awardSchemesMaster.awardSchemesMaster;
export const getAwardSchemesLoading = ({ awardSchemesMaster }) =>
  awardSchemesMaster.loaders.common;
export const getErrorBonusSchemes = ({ awardSchemesMaster }) =>
  awardSchemesMaster.errors.error;
export const getAwardSchemesLoadingDelete = ({ awardSchemesMaster }) =>
  awardSchemesMaster.loaders.delete;
export const getSuccessDeleteBonusSchemes = ({ awardSchemesMaster }) =>
  awardSchemesMaster.success.delete;
export const getErrorBonusSchemesDelete = ({ awardSchemesMaster }) =>
  awardSchemesMaster.errors.delete;
export const getSuccessPost = ({ awardSchemesMaster }) =>
  awardSchemesMaster.success.post;
export const getAwardSchemesLoadingPost = ({ awardSchemesMaster }) =>
  awardSchemesMaster.loaders.post;
export const getErrorBonusSchemesPost = ({ awardSchemesMaster }) =>
  awardSchemesMaster.errors.post;
