import { createColumn } from "../../utils/utils";
import { Space, Avatar } from "antd";
import { formatDateString } from "../../utils/utils";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";

export const fired = [
  { name: "Уволенные", bol: true },
  { name: "Не уволенные", bol: false },
];

export const columns = () => {
  return [
    createColumn(
      "Сотрудник",
      undefined,
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.staff[0]?.y_avatar || row?.staff[0]?.y_avatar_big}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "170px",
                  display: "flex",
                }}
              >
                {row?.staff[0]?.position?.y_title}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Нанят",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.hiring_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Уволен",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.dismissal_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Статус",
      "is_working",
      (v) => {
        return v;
      },
      100
    ),
    createColumn(
      "Схема премирования",
      "network_master_bonusschemes",
      (v) => {
        return (
          <Space direction="vertical" key={v?.id}>
            {v?.map((item) => {
              return (
                <span
                  key={item?.id}
                  style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                >
                  {item?.scheme?.name}
                </span>
              );
            })}
          </Space>
        );
      },
      150
    ),
    createColumn(
      "Студии",
      "studio",
      (_, row) => {
        return (
          <Space direction="vertical" key={row?.id}>
            {row?.studios.map((item) => {
              return (
                <span
                  key={item?.id}
                  style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                >
                  {item?.y_title}
                </span>
              );
            })}
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Пользователь",
      "user",
      (_, row) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ textWrap: "nowrap" }}>
              {row?.staff[0]?.user?.external_data?.y_name}
            </span>
            <span
              style={{
                color: "#8B8B8B",
                fontSize: 12,
                lineHeight: "14.52px",
                textWrap: "nowrap",
              }}
            >
              {row?.staff[0]?.user?.external_data?.y_phone &&
                `+${row?.staff[0]?.user?.external_data?.y_phone}`}
            </span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Работает официально",
      "is_official",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Официально" />
        ) : (
          <img src={iconNotSuccessful} alt="Не официально" />
        );
      },
      100
    ),
  ];
};
