export const getCalculationNetworkData = ({ calculationPremiumsNetwork }) =>
  calculationPremiumsNetwork.calculationPremiumsNetwork;
export const getCalculationNetworkLoading = ({ calculationPremiumsNetwork }) =>
  calculationPremiumsNetwork.loaders.common;
export const getError = ({ calculationPremiumsNetwork }) =>
  calculationPremiumsNetwork.errors.error;
export const getOpenCalculationPremiumsNetworkSelector = ({
  calculationPremiumsNetwork,
}) => calculationPremiumsNetwork.openCalculationPremiumsNetwork;
export const getSuccess = ({ calculationPremiumsNetwork }) =>
  calculationPremiumsNetwork.success.success;
export const getSubmittedCalculationNetworkSubmittedSelector = ({
  calculationPremiumsNetwork,
}) => calculationPremiumsNetwork.isCalculationPremiumsNetworkSubmitted;
