import { createColumn } from "../../utils/utils";
import { Space, Avatar } from "antd";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { formatDateString } from "../../utils/utils";

export const columns = () => {
  return [
    createColumn(
      "Студия",
      "studio",
      (v) => {
        return <span>{v?.y_title}</span>;
      },
      100
    ),
    createColumn(
      "Сотрудник",
      "user",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "170px",
                  display: "flex",
                }}
              >
                {row?.position?.y_title}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Нанят",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.hiring_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Уволен",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.y_dismissal_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Статус",
      "y_fired",
      (v) => {
        return !v ? "Работает" : "Уволен";
      },
      100
    ),
    createColumn(
      "Работает официально",
      "is_official",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Официально" />
        ) : (
          <img src={iconNotSuccessful} alt="Не официально" />
        );
      },
      100
    ),
    createColumn("Рейтинг", "y_rating", undefined, 100),
  ];
};
