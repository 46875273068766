export const getAwardSchemesData = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.awardSchemesNetwork;
export const getAwardSchemesLoading = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.loaders.common;
export const getError = ({ awardSchemesNetwork }) => awardSchemesNetwork.errors;
export const getOpenAwardSchemesCreateSelector = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.openAwardSchemesCreate;
export const getSuccessCreate = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.success.create;
export const getAwardSchemesLoadingCreate = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.loaders.create;
export const getOpenAwardSchemesEditSelector = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.openAwardSchemesEdit;
export const getSuccessEdit = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.success.edit;
export const getAwardSchemesLoadingEdit = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.loaders.edit;
export const getAwardSchemesNextPage = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.nextPage;
export const getSuccessDelete = ({ awardSchemesNetwork }) =>
  awardSchemesNetwork.success.delete;
