import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const getExpenses = createAsyncThunk(
  "getExpenses",
  async ({ network_y_id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/analytics/network/${network_y_id}/network_expenses/`
      );
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const postExpenses = createAsyncThunk(
  "postExpenses",
  async ({ network_y_id, params }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/analytics/network/${network_y_id}/network_expenses/`,
        params
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  expenses: null,
  success: {
    success: false,
    edit: false,
  },
  errors: {
    error: null,
    edit: null,
  },
  loaders: {
    common: false,
  },
};

const expensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorEdit: (state) => {
      state.errors.edit = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
  },

  extraReducers: (builder) => {
    // getExpenses
    builder.addCase(getExpenses.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(getExpenses.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.expenses = payload;
      state.success.success = true;
    });
    builder.addCase(getExpenses.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    // postExpenses
    builder.addCase(postExpenses.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(postExpenses.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.edit = true;
    });
    builder.addCase(postExpenses.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload;
    });
  },
});
export const { clearError, clearErrorEdit, clearSuccess, clearSuccessEdit } =
  expensesSlice.actions;
export default expensesSlice.reducer;
