export const getCorrectionsNetworkData = ({ networkCorrections }) =>
  networkCorrections.networkCorrections;
export const getCorrectionsNetworkLoading = ({ networkCorrections }) =>
  networkCorrections.loaders.common;
export const getError = ({ networkCorrections }) =>
  networkCorrections.errors.error;
export const getSuccess = ({ networkCorrections }) =>
  networkCorrections.success.success;
export const getOpenCorrectionsNetworkSelector = ({ networkCorrections }) =>
  networkCorrections.openCorrectionsNetwork;
export const getEventsNextPage = ({ networkCorrections }) =>
  networkCorrections.nextPage;

export const getErrorCreate = ({ networkCorrections }) =>
  networkCorrections.errors.create;
export const getSuccessCreate = ({ networkCorrections }) =>
  networkCorrections.success.create;
export const getCorrectionsNetworkPostLoading = ({ networkCorrections }) =>
  networkCorrections.loaders.post;
