import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const postBonusSchemes = createAsyncThunk(
  "postBonusSchemes",
  async ({ studio_y_id, schemes }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_master_bonus_schemes/studio/${studio_y_id}/schemes/`,
        schemes
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBonusSchemes = createAsyncThunk(
  "deleteBonusSchemes",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_master_bonus_schemes/studio/${studio_y_id}/schemes/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  awardSchemesMaster: null,
  success: {
    success: false,
    delete: false,
    post: false,
  },
  errors: {
    error: null,
    delete: null,
    post: null,
  },
  loaders: {
    common: false,
    delete: false,
    post: false,
  },
};

const awardSchemesMasterSlice = createSlice({
  name: "awardSchemesMasterSlice",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorDelete: (state) => {
      state.errors.delete = null;
    },
    clearErrorPost: (state) => {
      state.errors.post = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearSuccessBonusSchemesDelete: (state) => {
      state.success.delete = false;
    },
    clearSuccessBonusSchemesPost: (state) => {
      state.success.post = false;
    },
  },

  extraReducers: (builder) => {
    // postBonusSchemes
    builder.addCase(postBonusSchemes.pending, (state) => {
      state.loaders.post = true;
      state.errors.post = null;
    });
    builder.addCase(postBonusSchemes.fulfilled, (state) => {
      state.loaders.post = false;
      state.success.post = true;
    });
    builder.addCase(postBonusSchemes.rejected, (state, action) => {
      state.loaders.post = false;
      state.errors.post = action?.payload?.data;
    });
    // deleteBonusSchemes
    builder.addCase(deleteBonusSchemes.pending, (state) => {
      state.loaders.delete = true;
      state.errors.delete = null;
    });
    builder.addCase(deleteBonusSchemes.fulfilled, (state) => {
      state.loaders.delete = false;
      state.success.delete = true;
    });
    builder.addCase(deleteBonusSchemes.rejected, (state, action) => {
      state.loaders.delete = false;
      state.errors.delete = action?.payload;
    });
  },
});
export const {
  clearError,
  clearErrorDelete,
  clearErrorPost,
  clearSuccess,
  clearSuccessBonusSchemesDelete,
  clearSuccessBonusSchemesPost,
} = awardSchemesMasterSlice.actions;
export default awardSchemesMasterSlice.reducer;
