import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const getNetworks = createAsyncThunk(
  "getNetworks",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/networks/${studio_y_id}/`);
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStudioDropDown = createAsyncThunk(
  "getStudioDropDown",
  async (y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/networks/${y_id}/get_studios/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  networks: null,
  studioDropDown: null,
  openComplaintsNetwork: false,
  success: {
    success: false,
  },
  errors: {
    error: null,
    networks: null,
    studioDropDown: null,
  },
  loaders: {
    studioDropDown: false,
    networks: false,
    common: false,
  },
};

const networksSlice = createSlice({
  name: "networksSlice",
  initialState,
  reducers: {
    clearErrorNetwork: (state) => {
      state.errors.error = null;
    },
    setOpenComplaintsNetwork: (state) => {
      state.openComplaintsNetwork = !state.openComplaintsNetwork;
    },
  },

  extraReducers: (builder) => {
    //getNetworks
    builder.addCase(getNetworks.pending, (state) => {
      state.loaders.networks = true;
    });
    builder.addCase(getNetworks.fulfilled, (state, { payload }) => {
      state.networks = payload;
      state.loaders.networks = false;
    });
    builder.addCase(getNetworks.rejected, (state, action) => {
      state.loaders.networks = false;
      state.errors.networks = action.payload;
    });
    //getStudioDropDown
    builder.addCase(getStudioDropDown.pending, (state) => {
      state.loaders.studioDropDown = true;
    });
    builder.addCase(getStudioDropDown.fulfilled, (state, { payload }) => {
      state.studioDropDown = payload;
      state.loaders.studioDropDown = false;
    });
    builder.addCase(getStudioDropDown.rejected, (state, action) => {
      state.loaders.studioDropDown = false;
      state.errors.studioDropDown = action.payload;
    });
  },
});
export const { setOpenComplaintsNetwork, clearErrorNetwork } =
  networksSlice.actions;
export default networksSlice.reducer;
