export const getCalculationData = ({ calculationPremiums }) =>
  calculationPremiums.calculationPremiums;
export const getCalculationLoading = ({ calculationPremiums }) =>
  calculationPremiums.loaders.common;
export const getError = ({ calculationPremiums }) =>
  calculationPremiums.errors.error;
export const getOpenCalculationPremiumsSelector = ({ calculationPremiums }) =>
  calculationPremiums.openCalculationPremiums;
export const getSuccess = ({ calculationPremiums }) =>
  calculationPremiums.success.success;
export const getSubmittedCalculationPremiumsSelector = ({
  calculationPremiums,
}) => calculationPremiums.isCalculationSubmitted;
