export const getCalculationData = ({ calculation }) => calculation.calculation;
export const getCalculationLoading = ({ calculation }) =>
  calculation.loaders.common;
export const getError = ({ calculation }) => calculation.errors.error;
export const getOpenCalculationSelector = ({ calculation }) =>
  calculation.openCalculation;
export const getSuccess = ({ calculation }) => calculation.success.success;
export const getSuccessCreate = ({ calculation }) => calculation.success.create;
export const getErrorCreate = ({ calculation }) => calculation.errors.create;
export const getSubmittedCalculationSelector = ({ calculation }) =>
  calculation.isCalculationSubmitted;
