export const getAwardSchemesData = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.awardSchemesMasterNetwork;
export const getAwardSchemesLoading = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.loaders.common;
export const getErrorBonusSchemes = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.errors.error;
export const getAwardSchemesLoadingDelete = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.loaders.delete;
export const getSuccessDeleteBonusSchemes = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.success.delete;
export const getErrorBonusSchemesDelete = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.errors.delete;
export const getSuccessPost = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.success.post;
export const getAwardSchemesLoadingPost = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.loaders.post;
export const getErrorBonusSchemesPost = ({ awardSchemesMasterNetwork }) =>
  awardSchemesMasterNetwork.errors.post;
