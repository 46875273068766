import { createColumn } from "../../utils/utils";
import { Space, Avatar, Checkbox } from "antd";

export const columns = ({
  isOpenCalculation,
  calculationData,
  isCheckedAll,
  setIsCheckedAll,
  setCheckedRows,
  checkedRows,
  formatDateString,
  ordering,
}) => {
  return [
    createColumn(
      () => {
        const hasTrueRow = calculationData?.some(
          (row) => row.payments.length === 0 && row.total_salary > 0
        );

        return (
          isOpenCalculation &&
          hasTrueRow && (
            <Checkbox
              checked={isCheckedAll}
              onChange={(e) => {
                e.stopPropagation();
                setIsCheckedAll(e.target.checked);
                if (e.target.checked) {
                  const visibleRows = calculationData?.filter(
                    (row) => row.payments.length === 0 && row.total_salary > 0
                  );
                  setCheckedRows(visibleRows.map((row) => row));
                } else {
                  setCheckedRows([]);
                }
              }}
            />
          )
        );
      },
      "checkbox",
      (_, row) => {
        const isChecked = checkedRows.includes(row);
        return (
          isOpenCalculation &&
          row.payments.length === 0 &&
          row.total_salary > 0 && (
            <Checkbox
              checked={isChecked}
              onChange={(evt) => {
                evt.stopPropagation();
                let newCheckedRows;

                if (!isChecked) {
                  newCheckedRows = [...checkedRows, row];
                } else {
                  newCheckedRows = checkedRows.filter(
                    (item) => item.master.id !== row.master.id
                  );
                }
                setCheckedRows(newCheckedRows);

                const visibleRows = calculationData?.filter(
                  (row) => row.payments.length === 0 && row.total_salary > 0
                );
                const isAllVisibleChecked =
                  newCheckedRows.length === visibleRows.length;

                setIsCheckedAll(isAllVisibleChecked);
              }}
            />
          )
        );
      },
      30,
      "center"
    ),
    createColumn(
      "Mастер",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "170px",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      50,
      undefined,
      ordering?.master,
      () => {
        return 0;
      }
    ),
    createColumn(
      "Зарплата по Yclients",
      "salary",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Штраф",
      "total_penalty",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Бонус",
      "total_bonus",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Премия",
      "total_bounty",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Выплата до зарплаты",
      "total_prepayment",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Официальная зарплата",
      "total_official_salary",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Выплаты",
      "payments",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {v?.map((item) => {
              return (
                <Space key={item.id} direction="horizontal" style={{ gap: 5 }}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {formatDateString(item?.period_start_date)} -{" "}
                    {formatDateString(item?.period_end_date)}
                  </span>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 700,
                      textWrap: "nowrap",
                    }}
                  >
                    {item?.paid?.toLocaleString("ru-RU")} р.
                  </span>
                </Space>
              );
            })}
          </Space>
        );
      },
      150
    ),
    createColumn(
      "К выплате",
      "total_salary",
      (v) => {
        const color = v < 0 ? "red" : "black";
        return (
          <div style={{ color: color }}>{v.toLocaleString("ru-RU")} р.</div>
        );
      },
      100
    ),
  ];
};
