import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, limit = false, cursor = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getFails = createAsyncThunk(
  "getFails",
  async (
    { studio_y_id, filters, filtersArray, limit, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_fails/studio/${studio_y_id}/fails/`,
        {
          params: checkFilters(filters, filtersArray, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const getTypeDropDown = createAsyncThunk(
  "getTypeDropDown",
  async (studio_y_id, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_fail_types/studio/${studio_y_id}/fail_types/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const postFail = createAsyncThunk(
  "postFail",
  async ({ studio_y_id, formData }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_fails/studio/${studio_y_id}/fails/`,
        formData
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const patchFail = createAsyncThunk(
  "patchFail",
  async ({ studio_y_id, id, formData }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/studio_fails/studio/${studio_y_id}/fails/${id}/`,
        formData
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFail = createAsyncThunk(
  "deleteFail",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_fails/studio/${studio_y_id}/fails/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFailPhoto = createAsyncThunk(
  "deleteFailPhoto",
  async ({ studio_y_id, id, uid }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_fail_photos/studio/${studio_y_id}/fail/${id}/photos/${uid}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  fails: null,
  typeDropDown: null,
  openFails: false,
  nextPage: null,
  success: {
    success: false,
    create: false,
    edit: false,
    successPhoto: false,
  },
  errors: {
    error: null,
    edit: null,
    create: null,
    delete: null,
    typeDropDown: null,
    deletePhoto: null,
  },
  loaders: {
    typeDropDown: false,
    post: false,
    patch: false,
    common: false,
  },
};

const failsSlice = createSlice({
  name: "fails",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorEdit: (state) => {
      state.errors.edit = null;
    },
    clearErrorCreate: (state) => {
      state.errors.create = null;
    },
    setOpenFails: (state) => {
      state.openFails = !state.openFails;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
    clearErrorTypeDropDown: (state) => {
      state.errors.typeDropDown = null;
    },
  },

  extraReducers: (builder) => {
    // getFails
    builder.addCase(getFails.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getFails.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.fails = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getFails.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    //getTypeDropDown
    builder.addCase(getTypeDropDown.pending, (state) => {
      state.loaders.typeDropDown = true;
    });
    builder.addCase(getTypeDropDown.fulfilled, (state, { payload }) => {
      state.typeDropDown = payload;
      state.loaders.typeDropDown = false;
    });
    builder.addCase(getTypeDropDown.rejected, (state, action) => {
      state.loaders.typeDropDown = false;
      state.errors.typeDropDown = action.payload;
    });
    // postFail
    builder.addCase(postFail.pending, (state) => {
      state.loaders.post = true;
      state.errors.create = null;
    });
    builder.addCase(postFail.fulfilled, (state, { payload }) => {
      state.loaders.post = false;
      state.success.create = true;
      // state.fails = payload;
    });
    builder.addCase(postFail.rejected, (state, action) => {
      state.loaders.post = false;
      state.errors.create = action?.payload;
    });
    // patchFail
    builder.addCase(patchFail.pending, (state) => {
      state.loaders.patch = true;
      state.errors.edit = null;
    });
    builder.addCase(patchFail.fulfilled, (state, { payload }) => {
      state.loaders.patch = false;
      state.success.edit = true;
      // state.fails = payload;
    });
    builder.addCase(patchFail.rejected, (state, action) => {
      state.loaders.patch = false;
      state.errors.edit = action?.payload;
    });
    // deleteFail
    builder.addCase(deleteFail.pending, (state) => {
      state.loaders.common = true;
      state.errors.delete = null;
    });
    builder.addCase(deleteFail.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.success = true;
      // state.fails = payload;
    });
    builder.addCase(deleteFail.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.delete = action?.payload;
    });
    // deleteFailPhoto
    builder.addCase(deleteFailPhoto.pending, (state) => {
      state.loaders.common = true;
      state.errors.deletePhoto = null;
    });
    builder.addCase(deleteFailPhoto.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.successPhoto = true;
      // state.fails = payload;
    });
    builder.addCase(deleteFailPhoto.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.deletePhoto = action?.payload;
    });
  },
});
export const {
  clearError,
  setOpenFails,
  clearErrorEdit,
  clearErrorCreate,
  clearSuccess,
  clearSuccessEdit,
  clearSuccessCreate,
  clearErrorTypeDropDown,
} = failsSlice.actions;
export default failsSlice.reducer;
