import { createColumn } from "../../utils/utils";
import { Space, Avatar, Row, Col, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { convertToLocalDate } from "../../utils/utils";
import styles from "./styles.module.css";

export const status = [
  { name: "Да", bol: true },
  { name: "Нет", bol: false },
];

export const columns = () => {
  return [
    createColumn(
      "Студия",
      "studio",
      (v) => {
        return <span>{v?.y_title}</span>;
      },
      100
    ),
    createColumn(
      "Дата",
      "pub_date",
      (v) => {
        let value = convertToLocalDate(v);
        const [date] = value.split(" ");
        return <span style={{ color: "#4B4F55" }}>{date}</span>;
      },
      50
    ),
    createColumn(
      "Mастер",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Тип",
      "corr_type",
      (v) => {
        return (
          <Row>
            <Col style={{ fontSize: "12px" }}>{v}</Col>
          </Row>
        );
      },
      100
    ),
    createColumn(
      "Сумма",
      "cost",
      (v) => {
        if (v) {
          return <p>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Комментарий",
      "comment",
      (v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>{v}</span>}
          >
            <p className={styles.commentText}>{v}</p>
          </Tooltip>
        );
      },
      150
    ),
    createColumn(
      "Коррекция из оборота",
      "rev_corr",
      (v) => {
        if (v) {
          return <p>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Создано автоматически",
      "created_by_system",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
  ];
};
