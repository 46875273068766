import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Input, Table, Spin, Space, Button, Select, Badge } from "antd";
import filters from "../../images/filters.svg";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  isInclude,
  selectDropdown,
  convertToLocalDate,
  convertDateToISO861,
  expandIcon,
  formatDateString,
  reversFormatDateString,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getStudioDropDown } from "../../store/networks";
import {
  getStudioData,
  getStudioDropDownSelector,
} from "../../store/networks/selectors";
import { getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getNetworkCalculation,
  postNetworkCalculation,
  clearError,
  clearErrorCreate,
  clearSuccessCreate,
  setCalculationNetworkSubmitted,
} from "../../store/networkCalculation";
import {
  getCalculationData,
  getOpenCalculationNetworkSelector,
  getCalculationLoading,
  getError,
  getSuccess,
  getSuccessCreate,
  getSubmittedCalculationNetworkSelector,
} from "../../store/networkCalculation/selectors";
import { getPositionNetworkDropDown } from "../../store/staff";
import { getPositionNetworkDataSelector } from "../../store/staff/selectors";
import { getFirstAndLastDayOfPreviousMonth } from "../../utils/getFirstAndLastDayOfPreviousMonth";
import displayErrors from "../../utils/displayErrors";
import { columns, status } from "./utils";

export const CalculationNetwork = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getCalculationLoading);
  const errorStudio = useSelector(getErrorStudio);
  const calculationData = useSelector(getCalculationData);
  const isOpenCalculationNetwork = useSelector(
    getOpenCalculationNetworkSelector
  );
  const isSubmittedCalculationNetwork = useSelector(
    getSubmittedCalculationNetworkSelector
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [search, setSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedStudio, setSelectedStudio] = useState([]);
  const [includePosition, setIncludePosition] = useState(true);
  const [includeStudio, setIncludeStudio] = useState(true);
  const [includeStatus, setIncludeStatus] = useState(true);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const { firstDay, lastDay } = getFirstAndLastDayOfPreviousMonth();
  const formatDate = (date) => convertToLocalDate(date).split(" ")[0];
  const [dateAfterFilter, setDateAfterFilter] = useState(formatDate(firstDay));
  const [dateBeforeFilter, setDateBeforeFilter] = useState(formatDate(lastDay));
  const [openDate, setOpenDate] = useState(false);
  // const [openCalculationDetail, setOpenCalculationDetail] = useState(false);
  const studioList = useSelector(getStudioDropDownSelector);
  const positionList = useSelector(getPositionNetworkDataSelector);
  const [viewDataFilter, setViewDataFilter] = useState(
    `${formatDate(firstDay)} - ${formatDate(lastDay)}`
  );
  const openInfo = useSelector(getOpenInfoSelector);
  const success = useSelector(getSuccess);
  const successCreate = useSelector(getSuccessCreate);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [sum, setSum] = useState(0);
  const [salary, setSalary] = useState(0);
  const [penalty, setPenalty] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [bounty, setBounty] = useState(0);
  const [prepayment, setPrepayment] = useState(0);
  const [officialSalary, setOfficialSalary] = useState(0);
  const [ordering, setOrdering] = useState({
    studio: null,
  });

  const getOrderBy = () => {
    let orderBy = "";

    Object.keys(ordering).forEach((key) => {
      if (ordering[key] === "ascend") orderBy = key;
      if (ordering[key] === "descend") orderBy = "-" + key;
    });

    return orderBy;
  };

  const handleSort = (field) => {
    let tempOrdering = {
      studio: null,
    };

    switch (ordering[field]) {
      case "ascend":
        tempOrdering[field] = "descend";
        break;
      case "descend":
        tempOrdering[field] = null;
        break;
      default:
        tempOrdering[field] = "ascend";
    }

    setOrdering(tempOrdering);
  };

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const fetchData = () => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    dispatch(clearError());
    dispatch(clearErrorCreate());

    if (dateAfterFilter && dateBeforeFilter) {
      dispatch(
        getNetworkCalculation({
          network_y_id:
            JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
            studioData?.y_id,
          filters: filters,
          filtersArray: filtersArray,
          ordering: getOrderBy(),
        })
      );
    }
  };

  const clearFilters = () => {
    setSearch("");
    setViewDataFilter(null);
    setDateBeforeFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
    setSelectedPosition([]);
    setSelectedStatus(null);
    setSelectedStudio([]);
    setIncludePosition(true);
    setIncludeStatus(true);
    setIncludeStudio(true);
  };

  useEffect(() => {
    if (isSubmittedCalculationNetwork && checkedRows?.length > 0) {
      const payments = checkedRows.flatMap((row) => {
        return row.children.map((item) => {
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString();

          return {
            paid: item.total_salary,
            period_start_date: reversFormatDateString(dateAfterFilter),
            period_end_date: reversFormatDateString(dateBeforeFilter),
            pay_date_time: formattedDate,
            related_corrections: item.related_corrections || [],
            master: item.master.id,
            studio: item.master.studio.id,
          };
        });
      });

      dispatch(
        postNetworkCalculation({
          network_y_id: studioData?.y_id,
          payments: payments,
        })
      );

      setCheckedRows([]);
      dispatch(clearSuccessCreate());
      dispatch(setCalculationNetworkSubmitted(false));
    }
  }, [isSubmittedCalculationNetwork]);

  useEffect(() => {
    updateFiltersArr(
      "position",
      includePosition,
      selectedPosition,
      setFiltersArray,
      filtersArray
    );
  }, [includePosition, selectedPosition]);

  useEffect(() => {
    updateFiltersArr(
      "studio",
      includeStudio,
      selectedStudio,
      setFiltersArray,
      filtersArray
    );
  }, [includeStudio, selectedStudio]);

  useEffect(() => {
    updateFilters(
      "payment",
      includeStatus,
      selectedStatus,
      setFiltersArray,
      filtersArray
    );
  }, [includeStatus, selectedStatus]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    // dateAfterFilter,
    // dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
    ordering,
  ]);

  useEffect(() => {
    if (studioData) {
      dispatch(
        getStudioDropDown(
          JSON.parse(sessionStorage.getItem("studio"))?.y_id || studioData?.y_id
        )
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, studioData?.y_id]);

  useEffect(() => {
    if (studioData) {
      dispatch(
        getPositionNetworkDropDown(
          JSON.parse(sessionStorage.getItem("studio"))?.y_id || studioData?.y_id
        )
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, studioData?.y_id]);

  useEffect(() => {
    const calculateSums = (rows) => {
      return rows.reduce(
        (acc, row) => {
          acc.total += row.total;
          acc.y_client_salary_data += row.y_client_salary_data;
          acc.total_penalty += row.total_penalty;
          acc.total_bonus += row.total_bonus;
          acc.total_bounty += row.total_bounty;
          acc.total_prepayment += row.total_prepayment;
          acc.total_official_salary += row.total_official_salary;
          return acc;
        },
        {
          total: 0,
          y_client_salary_data: 0,
          total_penalty: 0,
          total_bonus: 0,
          total_bounty: 0,
          total_prepayment: 0,
          total_official_salary: 0,
        }
      );
    };

    const updateSums = (sums) => {
      setSum(sums.total.toLocaleString("ru-RU"));
      setSalary(sums.y_client_salary_data.toLocaleString("ru-RU"));
      setPenalty(sums.total_penalty.toLocaleString("ru-RU"));
      setBonus(sums.total_bonus.toLocaleString("ru-RU"));
      setBounty(sums.total_bounty.toLocaleString("ru-RU"));
      setPrepayment(sums.total_prepayment.toLocaleString("ru-RU"));
      setOfficialSalary(sums.total_official_salary.toLocaleString("ru-RU"));
    };

    if (isOpenCalculationNetwork) {
      if (checkedRows?.length > 0) {
        const sums = calculateSums(checkedRows);
        updateSums(sums);
      } else {
        updateSums({
          total: 0,
          y_client_salary_data: 0,
          total_penalty: 0,
          total_bonus: 0,
          total_bounty: 0,
          total_prepayment: 0,
          total_official_salary: 0,
        });
      }
    } else if (calculationData) {
      const aggregatedData = calculateSums(calculationData);
      updateSums(aggregatedData);
    } else {
      updateSums({
        total: 0,
        y_client_salary_data: 0,
        total_penalty: 0,
        total_bonus: 0,
        total_bounty: 0,
        total_prepayment: 0,
        total_official_salary: 0,
      });
    }
  }, [calculationData, isOpenCalculationNetwork, checkedRows]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const previousMonth = currentDate.getMonth() - 1;
  //   const firstDayOfPreviousMonth = new Date(
  //     currentDate.getFullYear(),
  //     previousMonth,
  //     1
  //   );
  //   const lastDayOfPreviousMonth = new Date(
  //     currentDate.getFullYear(),
  //     previousMonth + 1,
  //     0
  //   );

  //   setDateAfterFilter(
  //     convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0]
  //   );
  //   setDateBeforeFilter(
  //     convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
  //   );
  //   setViewDataFilter(
  //     convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0] +
  //       " - " +
  //       convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
  //   );
  // }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            setViewDataFilter={setViewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            setDateAfterFilter={setDateAfterFilter}
            setDateBeforeFilter={setDateBeforeFilter}
            fetchData={fetchData}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={search}
                placeholder="Поиск по имени мастера"
                suffix={
                  <CloseOutlined
                    style={{
                      color: search ? "red" : "black",
                    }}
                    onClick={() => {
                      setSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>

        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={search}
                  placeholder="Поиск по телефону, имени клиента, мастеру и типу переделки"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: search ? "red" : "black",
                      }}
                      onClick={() => {
                        setSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStudio)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Название студии"
                  mode="multiple"
                  options={studioList?.studios?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_title,
                    };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStudio, setIncludeStudio)
                  }
                  value={selectedStudio}
                  onChange={setSelectedStudio}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includePosition)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder="Должность"
                  style={{ width: "100%", textAlign: "left" }}
                  options={positionList?.map((option) => {
                    return {
                      value: option.pk,
                      label: option.y_title,
                    };
                  })}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includePosition, setIncludePosition)
                  }
                  value={selectedPosition}
                  onChange={setSelectedPosition}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStatus)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Выплаты"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  options={status.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>
            </div>
          </>
        )}
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {calculationData?.length || 0} из{" "}
        {calculationData?.length || 0}
      </div>
      <div
        style={{
          maxHeight:
            openFiltersRow && isOpenCalculationNetwork
              ? "calc(100vh - 310px)"
              : openFiltersRow
              ? "calc(100vh - 270px)"
              : isOpenCalculationNetwork
              ? "calc(100vh - 240px)"
              : "calc(100vh - 210px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns({
            isOpenCalculationNetwork,
            calculationData,
            isCheckedAll,
            setIsCheckedAll,
            setCheckedRows,
            checkedRows,
            formatDateString,
            ordering,
          })}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={calculationData || []}
          locale={{
            triggerDesc: "сортировать от Я до А",
            triggerAsc: "сортировать от А до Я",
            cancelSort: "сбросить",
          }}
          onHeaderRow={() => {
            return {
              onClick: (e) => {
                switch (e.target.innerText) {
                  case "СТУДИЯ":
                    handleSort("studio");
                    break;
                }
              },
            };
          }}
          expandable={{
            expandIcon,
          }}
          footer={() => (
            <tr className="ant-table-row">
              {/* <td className="ant-table-cell" style={{ width: "27%" }}></td> */}
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Зарплата по Yclients:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {salary} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Штраф:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {penalty} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Бонус:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {bonus} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Премия:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {bounty} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Выплата до зарплаты:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {prepayment} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  Официальная зарплата:
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      textWrap: "nowrap",
                      alignSelf: "center",
                    }}
                  >
                    {officialSalary} р.
                  </span>
                </div>
              </td>
              <td className="ant-table-cell">
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "end",
                    gap: 5,
                  }}
                >
                  {isOpenCalculationNetwork ? "Выбрано" : "Итого"}:
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      alignSelf: "center",
                    }}
                  >
                    {sum}
                  </span>{" "}
                  р.
                </div>
              </td>
            </tr>
          )}
          // onRow={(record) => ({
          //   onClick: (evt) => {
          //     evt.stopPropagation();
          //     if (evt.target.type !== "checkbox") {
          //       setOpenCalculationDetail({
          //         open: true,
          //         record: record,
          //       });
          //     }
          //   },
          // })}
        />
      </div>
      {/* <div
        style={{
          fontSize: 16,
          fontWeight: 400,
          display: "flex",
          justifyContent: "end",
          gap: 5,
          marginTop: 5,
        }}
      >
        Итого к выплате:
        <span
          style={{
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {sum}
        </span>
        р.
      </div> */}
      {/* {openCalculationDetail && (
        <CalculationDetailModal
          openDetails={openCalculationDetail}
          setOpenDetails={setOpenCalculationDetail}
          viewDataFilter={viewDataFilter}
        />
      )} */}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по расчетам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
