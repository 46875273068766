export const getTypeData = ({ type }) => type.type;
export const getTypeLoading = ({ type }) => type.loaders.common;
export const getTypeLoadingPost = ({ type }) => type.loaders.post;
export const getTypeLoadingPatch = ({ type }) => type.loaders.patch;
export const getSuccessDelete = ({ type }) => type.success.delete;
export const getSuccessEdit = ({ type }) => type.success.edit;
export const getSuccessCreate = ({ type }) => type.success.create;
export const getError = ({ type }) => type.errors;
export const getTypeNextPage = ({ type }) => type.nextPage;
export const getOpenTypeSelector = ({ type }) => type.openType;
