import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Table, Spin, Space, Select, Button, Row, Col } from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  convertDateToISO861,
  reversFormatDateString,
  convertToLocalDate,
  isInclude,
  selectDropdown,
  updateFiltersArr,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData, getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import { getPositionDropDown } from "../../store/staff";
import {
  getCalculationPremiums,
  clearError,
  setCalculationPremiumsSubmitted,
} from "../../store/calculationPremiums";
import {
  getCalculationData,
  getOpenCalculationPremiumsSelector,
  getCalculationLoading,
  getError,
  getSuccess,
  getSubmittedCalculationPremiumsSelector,
} from "../../store/calculationPremiums/selectors";
import { getPositionDataSelector } from "../../store/staff/selectors";
import {
  postCorrections,
  clearErrorCreate,
  clearSuccessCreate,
} from "../../store/corrections";
import {
  getSuccessCreate,
  getErrorCreate,
  getCorrectionsPostLoading,
} from "../../store/corrections/selectors";
import displayErrors from "../../utils/displayErrors";
import { CalculationDetailModal } from "../../components/CalculationDetailModal";
import { ModalComponent } from "../../components/ModalComponent";
import { columns } from "./utils";
import { getFirstAndLastDayOfCurrentMonth } from "../../utils/getFirstAndLastDayOfCurrentMonth";

export const СalculationPremiumsPage = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const errorCor = useSelector(getErrorCreate);
  const isLoading = useSelector(getCalculationLoading);
  const isLoadingCor = useSelector(getCorrectionsPostLoading);
  const errorStudio = useSelector(getErrorStudio);
  const calculationData = useSelector(getCalculationData);
  const isOpenCalculationPremiums = useSelector(
    getOpenCalculationPremiumsSelector
  );
  const isSubmittedCalculationPremiums = useSelector(
    getSubmittedCalculationPremiumsSelector
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [filtersArray, setFiltersArray] = useState([]);
  const { firstDay, lastDay } = getFirstAndLastDayOfCurrentMonth();
  const formatDate = (date) => convertToLocalDate(date).split(" ")[0];
  const [dateAfterFilter, setDateAfterFilter] = useState(formatDate(firstDay));
  const [dateBeforeFilter, setDateBeforeFilter] = useState(formatDate(lastDay));
  const [openDate, setOpenDate] = useState(false);
  const [openCalculationDetail, setOpenCalculationDetail] = useState(false);
  const openInfo = useSelector(getOpenInfoSelector);
  const [viewDataFilter, setViewDataFilter] = useState(
    `${formatDate(firstDay)} - ${formatDate(lastDay)}`
  );
  const success = useSelector(getSuccess);
  const successCreate = useSelector(getSuccessCreate);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [includePosition, setIncludePosition] = useState(true);
  const [sum, setSum] = useState(0);
  const positionList = useSelector(getPositionDataSelector);

  const fetchData = () => {
    let filters = {};

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    dispatch(clearErrorStudio());
    dispatch(clearError());
    dispatch(clearErrorCreate());
    if (dateAfterFilter && dateBeforeFilter) {
      dispatch(
        getCalculationPremiums({
          studio_y_id:
            JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
            studioData?.y_id,
          filters: filters,
          filtersArray: filtersArray,
        })
      );
    }
  };

  const clearFilters = () => {
    setSelectedPosition([]);
    setIncludePosition(true);
  };

  useEffect(() => {
    if (
      !isOpenCalculationPremiums &&
      checkedRows?.length > 0 &&
      isSubmittedCalculationPremiums
    ) {
      const payments = checkedRows.map((item) => {
        return {
          corr_type: "Премия",
          start_date_bounty: reversFormatDateString(dateAfterFilter),
          end_date_bounty: reversFormatDateString(dateBeforeFilter),
          pub_date: reversFormatDateString(dateBeforeFilter),
          master: item?.master?.id,
          cost: item?.total,
          studio:
            JSON.parse(sessionStorage.getItem("studio"))?.id || studioData?.id,
        };
      });

      dispatch(
        postCorrections({
          studio_y_id:
            JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
            studioData?.y_id,
          params: payments,
        })
      );

      setCheckedRows([]);
      dispatch(clearSuccessCreate());
      dispatch(setCalculationPremiumsSubmitted(false));
    }
  }, [isSubmittedCalculationPremiums]);

  useEffect(() => {
    if (isOpenCalculationPremiums) {
      if (checkedRows?.length > 0) {
        const totalSum = checkedRows.reduce(
          (acc, row) => acc + (row.total || 0),
          0
        );

        setSum(totalSum);
      }
    }
  }, [calculationData, isOpenCalculationPremiums, checkedRows]);

  useEffect(() => {
    updateFiltersArr(
      "position_pk",
      includePosition,
      selectedPosition,
      setFiltersArray,
      filtersArray
    );
  }, [includePosition, selectedPosition]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    // dateAfterFilter,
    // dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
  ]);

  useEffect(() => {
    if (studioData) {
      dispatch(getPositionDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <div style={{ display: "flex", alignItems: "end" }}>
            <DatePickerPopover
              openDate={openDate}
              setOpenDate={setOpenDate}
              viewDataFilter={viewDataFilter}
              setViewDataFilter={setViewDataFilter}
              dateAfterFilter={dateAfterFilter}
              dateBeforeFilter={dateBeforeFilter}
              setDateAfterFilter={setDateAfterFilter}
              setDateBeforeFilter={setDateBeforeFilter}
              fetchData={fetchData}
            />
          </div>

          <Space direction="vertical" style={{ width: "100%" }}>
            {isInclude(includePosition)}
            <Select
              allowClear={true}
              optionFilterProp="label"
              mode="multiple"
              placeholder="Должность"
              style={{ width: "100%", textAlign: "left" }}
              options={positionList?.map((option) => {
                return {
                  value: option.pk,
                  label: option.y_title,
                };
              })}
              dropdownRender={(menu) =>
                selectDropdown(menu, includePosition, setIncludePosition)
              }
              value={selectedPosition}
              onChange={setSelectedPosition}
            />
          </Space>
          <div style={{ display: "flex", alignItems: "end" }}>
            {filtersArray?.length > 0 && (
              <Button
                onClick={clearFilters}
                style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
                type={"primary"}
              >
                Сбросить
              </Button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 9,
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Всего:
              </div>
              <div
                style={{
                  backgroundColor: `#B7E4C8`,
                  fontSize: 12,
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 102,
                  height: 22,
                  borderRadius: 5,
                  textWrap: "nowrap",
                }}
              >
                {(
                  calculationData?.results?.reduce((accumulator, current) => {
                    return accumulator + (current.total || 0);
                  }, 0) || 0
                ).toLocaleString("ru-RU")}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 9,
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Выбрано:
              </div>
              <div
                style={{
                  backgroundColor: `#B7E4C8`,
                  fontSize: 12,
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 102,
                  height: 22,
                  borderRadius: 5,
                  textWrap: "nowrap",
                }}
              >
                {sum.toLocaleString("ru-RU")}
              </div>
            </div>
          </div>
        </div>
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {calculationData?.results?.length || 0} из{" "}
        {calculationData?.count || 0}
      </div>
      <div
        style={{
          maxHeight: isOpenCalculationPremiums
            ? "calc(100vh - 270px)"
            : "calc(100vh - 225px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio || errorCor) &&
          displayErrors(error || errorStudio || errorCor)}
        <Table
          columns={columns({
            isOpenCalculationPremiums,
            calculationData,
            isCheckedAll,
            setIsCheckedAll,
            setCheckedRows,
            checkedRows,
          })}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={(isLoading || isLoadingCor) && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={calculationData?.results || []}
          // onRow={(record) => {
          //   if (isOpenCalculationPremiums) {
          //     return;
          //   } else {
          //     return {
          //       onClick: (evt) => {
          //         evt.stopPropagation();
          //         if (evt.target.type !== "checkbox") {
          //           setOpenCalculationDetail({
          //             open: true,
          //             record: record,
          //           });
          //         }
          //       },
          //     };
          //   }
          // }}
        />
      </div>
      {openCalculationDetail && (
        <CalculationDetailModal
          openDetails={openCalculationDetail}
          setOpenDetails={setOpenCalculationDetail}
          viewDataFilter={viewDataFilter}
        />
      )}
      {successCreate && (
        <ModalComponent
          open={successCreate}
          title="Коррекция успешно добавлена!"
          body={
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  style={{
                    width: "50%",
                    borderRadius: 4,
                    height: 48,
                    backgroundColor: "#76CEC9",
                  }}
                  type="primary"
                  onClick={() => {
                    dispatch(clearErrorCreate());
                    dispatch(clearSuccessCreate());
                  }}
                >
                  Ок
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            dispatch(clearErrorCreate());
            dispatch(clearSuccessCreate());
          }}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по расчетам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
