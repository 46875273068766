import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { getColorAndText, createColumn } from "../../utils/utils";
import styles from "./styles.module.css";

export const columns = ({ setOpenTypeDelete }) => {
  return [
    createColumn(
      "Тип жалобы",
      "name",
      (v) => {
        return <span style={{ color: "#4B4F55" }}>{v}</span>;
      },
      450
    ),
    createColumn(
      "Критичность",
      "critical_level",
      (v) => {
        return <span>{getColorAndText(v)}</span>;
      },
      450
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить тип жалоб</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenTypeDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      20
    ),
  ];
};
