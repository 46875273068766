import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const getType = createAsyncThunk(
  "getType",
  async ({ studio_y_id, pagination }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_fail_types/studio/${studio_y_id}/fail_types/`
      );
      return { data, pagination };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const postType = createAsyncThunk(
  "postType",
  async ({ studio_y_id, type }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_fail_types/studio/${studio_y_id}/fail_types/`,
        type
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const patchType = createAsyncThunk(
  "patchType",
  async ({ studio_y_id, id, type }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/studio_fail_types/studio/${studio_y_id}/fail_types/${id}/`,
        type
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteType = createAsyncThunk(
  "deleteType",
  async ({ studio_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/studio_fail_types/studio/${studio_y_id}/fail_types/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  type: null,
  nextPage: null,
  errors: null,
  openType: false,
  success: {
    success: false,
    create: false,
    edit: false,
    delete: false,
  },
  loaders: {
    common: false,
    post: false,
    patch: false,
  },
};

const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors = null;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    clearSuccessDelete: (state) => {
      state.success.delete = false;
    },
    setOpenType: (state) => {
      state.openType = !state.openType;
    },
  },

  extraReducers: (builder) => {
    // getType
    builder.addCase(getType.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
      state.nextPage = null;
    });
    builder.addCase(getType.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.type = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getType.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload?.data;
    });
    // postType
    builder.addCase(postType.pending, (state) => {
      state.loaders.post = true;
      state.errors = null;
    });
    builder.addCase(postType.fulfilled, (state, { payload }) => {
      state.loaders.post = false;
      state.success.create = true;
      // state.fails = payload;
    });
    builder.addCase(postType.rejected, (state, action) => {
      state.loaders.post = false;
      state.errors = action?.payload;
    });
    // patchType
    builder.addCase(patchType.pending, (state) => {
      state.loaders.patch = true;
      state.errors = null;
    });
    builder.addCase(patchType.fulfilled, (state, { payload }) => {
      state.loaders.patch = false;
      state.success.edit = true;
    });
    builder.addCase(patchType.rejected, (state, action) => {
      state.loaders.patch = false;
      state.errors = action?.payload;
    });
    // deleteType
    builder.addCase(deleteType.pending, (state) => {
      state.loaders.patch = true;
      state.errors = null;
    });
    builder.addCase(deleteType.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success.delete = true;
    });
    builder.addCase(deleteType.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload;
    });
  },
});
export const {
  clearError,
  clearSuccessCreate,
  clearSuccessEdit,
  clearSuccessDelete,
  setOpenType,
} = typeSlice.actions;
export default typeSlice.reducer;
