import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

const checkFilters = (filters, filtersArray, cursor = false, limit = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getNetworkCorrections = createAsyncThunk(
  "getNetworkCorrections",
  async (
    { network_y_id, filters, filtersArray, limit, cursor, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_corrections/network/${network_y_id}/corrections/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postNetworkCorrections = createAsyncThunk(
  "postNetworkCorrections",
  async ({ network_y_id, params }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/network_corrections/network/${network_y_id}/corrections/`,
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  networkCorrections: null,
  openCorrectionsNetwork: false,
  nextPage: null,
  success: {
    success: false,
    create: false,
  },
  errors: {
    error: null,
    create: null,
  },
  loaders: {
    common: false,
    post: false,
  },
};

const networkCorrectionsSlice = createSlice({
  name: "networkCorrectionsSlice",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorCreate: (state) => {
      state.errors.create = null;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    setOpenComplaintsNetwork: (state) => {
      state.openCorrectionsNetwork = !state.openCorrectionsNetwork;
    },
  },

  extraReducers: (builder) => {
    // getNetworkCorrections
    builder.addCase(getNetworkCorrections.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getNetworkCorrections.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.networkCorrections = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getNetworkCorrections.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    // postNetworkCorrections
    builder.addCase(postNetworkCorrections.pending, (state) => {
      state.loaders.post = true;
      state.errors.create = null;
    });
    builder.addCase(postNetworkCorrections.fulfilled, (state, { payload }) => {
      state.loaders.post = false;
      state.success.create = true;
      // state.fails = payload;
    });
    builder.addCase(postNetworkCorrections.rejected, (state, action) => {
      state.loaders.post = false;
      state.errors.create = action?.payload;
    });
  },
});
export const {
  setOpenCorrectionsNetwork,
  clearError,
  clearSuccessCreate,
  clearErrorCreate,
} = networkCorrectionsSlice.actions;
export default networkCorrectionsSlice.reducer;
