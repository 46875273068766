import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Checkbox,
  Switch,
  Row,
  Col,
  Button,
  Select,
  Spin,
  DatePicker,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { ModalComponent } from "../../components/ModalComponent";
import displayErrors from "../../utils/displayErrors";
import { convertDateISO } from "../../utils/utils";
import {
  getExpenses,
  postExpenses,
  clearError,
  clearErrorEdit,
  clearSuccessEdit,
} from "../../store/expenses";
import {
  getExpensesData,
  getExpensesLoading,
  getError,
  getSuccessEdit,
} from "../../store/expenses/selectors";
import { clearErrorNetwork } from "../../store/networks";
import { getStudioData } from "../../store/networks/selectors";
import styles from "./styles.module.css";

export const ExpensesPage = () => {
  const [formExpenses] = Form.useForm();
  const dispatch = useDispatch();
  const [distribute, setDistribute] = useState(false);
  const openSuccess = useSelector(getSuccessEdit);
  const studioData = useSelector(getStudioData);
  const expensesData = useSelector(getExpensesData);
  const error = useSelector(getError);
  const isLoading = useSelector(getExpensesLoading);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [sum, setSum] = useState(0);

  const handleCheckboxChange = (itemId) => {
    setSelectedCheckboxes((prev) => {
      const newSelected = prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId];

      redistributeSum(newSelected);
      return newSelected;
    });
  };

  const redistributeSum = (newSelected) => {
    if (sum > 0 && newSelected.length > 0) {
      // const amountPerStudio = sum / newSelected.length;
      const amountCalculator = distribute
        ? () => sum / newSelected.length
        : (item) => Math.round((sum * item.percentage) / 100);

      expensesData?.forEach((item) => {
        const amount = newSelected.includes(item.id)
          ? amountCalculator(item)
          : 0;
        formExpenses.setFieldsValue({ [`amount_${item.id}`]: amount });
      });
    } else {
      expensesData?.forEach((item) => {
        formExpenses.setFieldsValue({ [`amount_${item.id}`]: 0 });
        formExpenses.setFieldsValue({ [`account_${item.id}`]: null });
        formExpenses.setFieldsValue({ [`expense_${item.id}`]: null });
      });
    }
  };

  const fetchData = () => {
    dispatch(clearErrorNetwork());
    dispatch(clearError());

    dispatch(
      getExpenses({
        network_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
      })
    );
  };

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [studioData]);

  useEffect(() => {
    if (expensesData) {
      const allIds = expensesData.map((item) => item.id);
      setSelectedCheckboxes(allIds);
    }
  }, [expensesData]);

  useEffect(() => {
    redistributeSum(selectedCheckboxes);
  }, [sum, distribute, selectedCheckboxes, expensesData, formExpenses]);

  return (
    <>
      <Form
        layout="vertical"
        form={formExpenses}
        onFinish={(data) => {
          if (data.date) {
            delete data.sum;
            const expensesArray = expensesData
              .map((item) => {
                const studioId = item.id;
                const amount = data[`amount_${studioId}`];
                const accountId = data[`account_${studioId}`];
                const expenseId = data[`expense_${studioId}`];

                return {
                  studio_id: studioId,
                  amount: amount,
                  account_id: accountId,
                  expense_id: expenseId,
                  date: convertDateISO(data.date.$d),
                  comment: data.comment,
                };
              })
              .filter((item) => item.amount !== undefined && item.amount !== 0);

            dispatch(clearErrorEdit());
            dispatch(
              postExpenses({
                network_y_id: studioData?.y_id,
                params: expensesArray,
              })
            );
          }
        }}
        style={{
          width: "100%",
          overflow: "auto",
          height: "calc(100vh - 120px)",
        }}
      >
        {error && displayErrors(error)}
        <Row
          gutter={16}
          style={{ gap: 34, width: "100%", margin: 0, flexDirection: "column" }}
        >
          <Col
            span={11}
            style={{
              maxWidth: 450,
              padding: 0,
            }}
          >
            <Form.Item
              name="sum"
              rules={[
                {
                  required: true,
                  message: `Необходимо ввести сумму`,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Введите сумму"
                style={{ borderRadius: 2 }}
                onChange={(e) => setSum(Number(e.target.value))}
              />
            </Form.Item>

            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: `Необходимо выбрать дату`,
                },
              ]}
            >
              <DatePicker
                format="DD.MM.YYYY"
                cellWidth={10}
                timeColumnWidth={10}
                presetsMaxWidth={10}
                presetsWidth={10}
                style={{ width: "100%", borderRadius: 2 }}
                placeholder="Выберите дату"
              />
            </Form.Item>

            <Form.Item
              name="comment"
              rules={[
                {
                  required: true,
                  message: `Необходимо ввести комментарий`,
                },
              ]}
            >
              <TextArea
                placeholder="Комментарий для расхода в Yclients"
                autoSize={{
                  minRows: 4,
                  maxRows: 5,
                }}
                style={{ borderRadius: 2 }}
              />
            </Form.Item>
            <Form.Item className={styles.switch}>
              <Switch checked={distribute} onChange={setDistribute} />
              <span className={styles.text}>
                Распределить по обороту / Распределить поровну
              </span>
            </Form.Item>
          </Col>
          {isLoading ? (
            <Col span={11} className={styles.spinContainer}>
              <Spin size="large" />
            </Col>
          ) : (
            expensesData?.map((item) => {
              return (
                <Col span={11} className={styles.colContainer} key={item.id}>
                  <Row justify={"space-between"} style={{ margin: 0 }}>
                    <Col>
                      <Checkbox
                        checked={selectedCheckboxes.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                      <span style={{ marginLeft: 10 }}>{item?.name}</span>
                    </Col>
                    {!distribute && (
                      <Col>
                        <span>{Math.round(item?.percentage)} %</span>
                      </Col>
                    )}
                  </Row>
                  <Row
                    gutter={22}
                    justify={"space-between"}
                    style={{ margin: 0 }}
                  >
                    <Col span={7} style={{ padding: 0 }}>
                      <Form.Item
                        name={`amount_${item.id}`}
                        style={{ margin: 0 }}
                      >
                        <Input
                          disabled
                          placeholder="Введите сумму"
                          style={{ borderRadius: 2 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={16} style={{ padding: 0 }}>
                      <Form.Item
                        name={`account_${item.id}`}
                        rules={[
                          {
                            required: selectedCheckboxes.includes(item.id),
                            message: `Необходимо выбрать кассу`,
                          },
                        ]}
                        style={{ margin: 0 }}
                      >
                        <Select
                          style={{ borderRadius: 2, width: "100%", margin: 0 }}
                          options={item?.accounts?.map((option) => {
                            return {
                              value: option.y_id,
                              label: option.y_title,
                            };
                          })}
                          disabled={!selectedCheckboxes.includes(item.id)}
                          optionFilterProp="label"
                          showSearch={true}
                          allowClear={true}
                          //value={selectedType}
                          //onChange={setSelectedType}
                          placeholder="Выберите кассу"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name={`expense_${item.id}`}
                    rules={[
                      {
                        required: selectedCheckboxes.includes(item.id),
                        message: `Необходимо выбрать статью расходов`,
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Select
                      style={{ borderRadius: 2, width: "100%", margin: 0 }}
                      options={item?.expense_types?.map((option) => {
                        return {
                          value: option.y_id,
                          label: option.y_title,
                        };
                      })}
                      disabled={!selectedCheckboxes.includes(item.id)}
                      optionFilterProp="label"
                      showSearch={true}
                      allowClear={true}
                      //value={selectedType}
                      //onChange={setSelectedType}
                      placeholder="Выберите статью расходов"
                    />
                  </Form.Item>
                </Col>
              );
            })
          )}
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col
            style={{
              maxWidth: 450,
              padding: 0,
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button type="primary" htmlType="submit" className={styles.button}>
              <span>Внести в расходы</span>
            </Button>
          </Col>
        </Row>
      </Form>
      {openSuccess && (
        <ModalComponent
          open={openSuccess}
          title="Данные успешно добавлены!"
          body={
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  style={{
                    width: "50%",
                    borderRadius: 4,
                    height: 48,
                    backgroundColor: "#76CEC9",
                  }}
                  type="primary"
                  onClick={() => {
                    dispatch(clearErrorNetwork());
                    dispatch(clearError());
                    dispatch(clearSuccessEdit());
                  }}
                >
                  Ок
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            dispatch(clearErrorNetwork());
            dispatch(clearError());
            dispatch(clearSuccessEdit());
          }}
        />
      )}
    </>
  );
};
