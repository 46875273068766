import { PlusOutlined } from "@ant-design/icons";

export const uploadButton = (
  <button
    style={{
      border: 0,
      background: "none",
      width: 193,
      height: 193,
    }}
    type="button"
  >
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
        fontSize: 9,
      }}
    >
      Прикрепите фотографию
    </div>
  </button>
);
