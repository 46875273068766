import { Button, DatePicker, Popover, Space } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import {
  // FREQUENCY,
  resetDateFilterAfter,
  handleChangeDateAfterMount,
} from "../../utils/utils";

export const DatePickerPopoverMount = ({
  openDate,
  setOpenDate,
  viewDataFilter,
  setViewDataFilter,
  dateAfterFilter,
  setDateAfterFilter,
  fetchData,
}) => {
  return (
    <Space.Compact title="Выберите интервал">
      <Popover
        placement="bottomLeft"
        open={openDate}
        onOpenChange={() => {
          setOpenDate(!openDate);
        }}
        title={false}
        content={
          <div>
            <Space direction="vertical">
              <DatePicker
                picker="month"
                style={{ borderRadius: 2 }}
                format="MM.YYYY"
                onChange={(evt) =>
                  handleChangeDateAfterMount(
                    evt,
                    setDateAfterFilter,
                    setViewDataFilter
                  )
                }
                placeholder="Выберите месяц"
                value={
                  dateAfterFilter ? resetDateFilterAfter(dateAfterFilter) : null
                }
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    fetchData();
                    setOpenDate(!openDate);
                  }}
                >
                  Применить
                </Button>
              </div>
            </Space>
          </div>
        }
        trigger="click"
      >
        <Button style={{ display: "flex", borderRadius: 2 }}>
          <CalendarOutlined
            style={{
              color: "#6679A9",
            }}
          />
          {!viewDataFilter ? (
            <h4
              style={{
                padding: "0 5px",
                margin: 0,
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Выберите месяц
            </h4>
          ) : (
            Boolean(viewDataFilter) && (
              <h4 style={{ padding: "0 5px" }}>{viewDataFilter}</h4>
            )
          )}
        </Button>
      </Popover>
    </Space.Compact>
  );
};

export default DatePickerPopoverMount;
