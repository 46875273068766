import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

const checkFilters = (
  filters,
  filtersArray,
  ordering,
  cursor = false,
  limit = false
) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  if (ordering) {
    filters.ordering = ordering;
  }

  return filters;
};

export const getCalculationPremiums = createAsyncThunk(
  "getCalculationPremiums",
  async ({ studio_y_id, filters, filtersArray }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_master_bounty/studio/${studio_y_id}/master_bounty/`,
        {
          params: checkFilters(filters, filtersArray),
        }
      );
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  calculationPremiums: null,
  openCalculationPremiums: false,
  isCalculationSubmitted: false,
  nextPage: null,
  success: {
    success: false,
  },
  errors: {
    error: null,
  },
  loaders: {
    common: false,
  },
};

const calculationPremiumsSlice = createSlice({
  name: "calculationPremiums",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    setOpenCalculationPremiums: (state) => {
      state.openCalculationPremiums = !state.openCalculationPremiums;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    setCalculationPremiumsSubmitted: (state, action) => {
      state.isCalculationSubmitted = action.payload;
    },
  },

  extraReducers: (builder) => {
    // getCalculationPremiums
    builder.addCase(getCalculationPremiums.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(getCalculationPremiums.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.calculationPremiums = payload;
    });
    builder.addCase(getCalculationPremiums.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
  },
});
export const {
  clearError,
  setOpenCalculationPremiums,
  clearSuccess,
  setCalculationPremiumsSubmitted,
} = calculationPremiumsSlice.actions;
export default calculationPremiumsSlice.reducer;
