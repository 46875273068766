export const getAwardSchemesData = ({ awardSchemes }) =>
  awardSchemes.awardSchemes;
export const getAwardSchemesLoading = ({ awardSchemes }) =>
  awardSchemes.loaders.common;
export const getError = ({ awardSchemes }) => awardSchemes.errors;
export const getOpenAwardSchemesCreateSelector = ({ awardSchemes }) =>
  awardSchemes.openAwardSchemesCreate;
export const getSuccessCreate = ({ awardSchemes }) =>
  awardSchemes.success.create;
export const getAwardSchemesLoadingCreate = ({ awardSchemes }) =>
  awardSchemes.loaders.create;
export const getOpenAwardSchemesEditSelector = ({ awardSchemes }) =>
  awardSchemes.openAwardSchemesEdit;
export const getSuccessEdit = ({ awardSchemes }) => awardSchemes.success.edit;
export const getAwardSchemesLoadingEdit = ({ awardSchemes }) =>
  awardSchemes.loaders.edit;
export const getAwardSchemesNextPage = ({ awardSchemes }) =>
  awardSchemes.nextPage;
export const getSuccessDelete = ({ awardSchemes }) =>
  awardSchemes.success.delete;
