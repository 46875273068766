import React from "react";
import { Drawer, Typography, Descriptions, Row, Col, Image } from "antd";
import { DescItem, convertDate, getDaysWord } from "../../utils/utils";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";

export const DetailNetworkModal = ({ openDetails, setOpenDetails }) => {
  const createdAt = new Date(openDetails?.record?.created_at);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - createdAt);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return (
    <Drawer
      open={openDetails?.open}
      onCancel={() => {
        setOpenDetails({ open: false });
      }}
      onClose={() => {
        setOpenDetails({ open: false });
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Детали сетевой жалобы
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      <Descriptions
        bordered
        column={1}
        labelStyle={{ color: "black", width: 200 }}
        style={{ marginTop: "20px" }}
      >
        {DescItem("Студия", openDetails?.record?.studio?.y_title)}
        {DescItem("Дата и время", convertDate(openDetails?.record?.created_at))}
        {DescItem("Клиент", openDetails?.record?.client?.y_name)}
        {DescItem("Мастер", openDetails?.record?.master?.name)}
        {DescItem(
          "Тип",
          openDetails?.record?.fail_type?.length > 0
            ? openDetails?.record?.fail_type?.map((item) => {
                return (
                  <Row key={item?.id}>
                    <Col>{item?.name}</Col>
                  </Row>
                );
              })
            : "Нет данных"
        )}
        {DescItem(
          "Прошло дней",
          <span>
            {diffDays} {getDaysWord(diffDays)}
          </span>
        )}
        {DescItem(
          "Коммент",
          openDetails?.record?.fail_type?.comment || "Нет данных"
        )}
        {DescItem(
          "Записали",
          openDetails?.record?.fail_type?.repair_record ? (
            <img src={iconSuccessful} alt="Успешно" />
          ) : (
            <img src={iconNotSuccessful} alt="Не успешно" />
          )
        )}
        {DescItem(
          "Фото",
          openDetails?.record?.photos?.length > 0
            ? openDetails?.record?.photos?.map((item) => {
                return <Image key={item?.id} width={50} src={item?.photo} />;
              })
            : "Нет данных"
        )}
        {DescItem(
          "Вычет из ЗП мастера",
          openDetails?.record?.repair_cost ? (
            <p>{openDetails?.record?.repair_cost} p.</p>
          ) : (
            "Нет данных"
          )
        )}
        {DescItem(
          "Сумма скидки из ЗП мастера",
          openDetails?.record?.loyal_cost ? (
            <p>{openDetails?.record?.loyal_cost} p.</p>
          ) : (
            "Нет данных"
          )
        )}
        {DescItem(
          "Клиент вернулся",
          openDetails?.record?.is_returned ? (
            <img src={iconSuccessful} alt="Успешно" />
          ) : (
            <img src={iconNotSuccessful} alt="Не успешно" />
          )
        )}
      </Descriptions>
    </Drawer>
  );
};
