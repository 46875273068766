import { useState, useEffect } from "react";
import IconSettings from "../../images/iconSettings.svg";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

export const SettingsStudio = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: width > 640 ? 12 : 9,
          position: "relative",
          paddingBottom: width > 640 ? 25 : 16,
        }}
      >
        <div className={styles.container}>
          <div className={styles.containerTitle}>
            <img src={IconSettings} alt="Настройки" />
            <p className={styles.title}>Основные настройки студии</p>
          </div>
          <div className={styles.containerButton}>
            <p
              onClick={() => navigate("/user_management")}
              className={styles.text}
            >
              Пользователи для сетевого доступа
            </p>
            <p className={styles.text}>Настройки студии</p>
            <p
              onClick={() => navigate("/types_complaints")}
              className={styles.text}
            >
              Типы жалоб клиентов
            </p>
            <p
              onClick={() => navigate("/settings_admin")}
              className={styles.text}
            >
              Настройки администратора
            </p>
            <p
              onClick={() => navigate("/settings_planning")}
              className={styles.text}
            >
              Настройки планирования
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
