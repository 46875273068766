export const getFirstAndLastDayOfPreviousMonth = () => {
  const currentDate = new Date();
  const previousMonth = currentDate.getMonth() - 1;

  const firstDayOfPreviousMonth = new Date(
    currentDate.getFullYear(),
    previousMonth,
    1
  );

  const lastDayOfPreviousMonth = new Date(
    currentDate.getFullYear(),
    previousMonth + 1,
    0
  );

  return {
    firstDay: firstDayOfPreviousMonth,
    lastDay: lastDayOfPreviousMonth,
  };
};
