import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (
  filters,
  filtersArray,
  ordering,
  cursor = false,
  limit = false
) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  if (ordering) {
    filters.ordering = ordering;
  }

  return filters;
};

export const getCalculationPremiumsNetwork = createAsyncThunk(
  "getCalculationPremiumsNetwork",
  async ({ network_y_id, filters, filtersArray }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_master_bounty/network/${network_y_id}/master_bounty/`,
        {
          params: checkFilters(filters, filtersArray),
        }
      );
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  calculationPremiumsNetwork: null,
  openCalculationPremiumsNetwork: false,
  isCalculationPremiumsNetworkSubmitted: false,
  nextPage: null,
  success: {
    success: false,
  },
  errors: {
    error: null,
  },
  loaders: {
    common: false,
  },
};

const calculationPremiumsNetworkSlice = createSlice({
  name: "calculationPremiumsNetwork",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    setOpenCalculationPremiumsNetwork: (state) => {
      state.openCalculationPremiumsNetwork =
        !state.openCalculationPremiumsNetwork;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    setCalculationPremiumsNetworkPremiumsSubmitted: (state, action) => {
      state.isCalculationPremiumsNetworkSubmitted = action.payload;
    },
  },

  extraReducers: (builder) => {
    // getCalculationPremiumsNetwork
    builder.addCase(getCalculationPremiumsNetwork.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(
      getCalculationPremiumsNetwork.fulfilled,
      (state, { payload }) => {
        state.loaders.common = false;
        state.calculationPremiumsNetwork = payload;
      }
    );
    builder.addCase(getCalculationPremiumsNetwork.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
  },
});
export const {
  clearError,
  setOpenCalculationPremiumsNetwork,
  clearSuccess,
  setCalculationPremiumsNetworkPremiumsSubmitted,
} = calculationPremiumsNetworkSlice.actions;
export default calculationPremiumsNetworkSlice.reducer;
