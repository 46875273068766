import { useState, useEffect } from "react";
import { Button, Col, Drawer, Form, InputNumber, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  patchPlanning,
  clearSuccessEdit,
  clearError,
  setOpenPlanningEdit,
} from "../../store/planning";
import {
  getSuccessEdit,
  getError,
  getPlanningLoadingEdit,
} from "../../store/planning/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import { throwFormError, convertDateToISO861 } from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";
import { LoaderModal } from "../LoaderModal";

export const EditPlanningModal = ({
  openPlanningEdit,
  dateAfter,
  dateBefore,
  plan,
}) => {
  const [formEditPlanning] = Form.useForm();
  const dispatch = useDispatch();
  const openSuccessEdit = useSelector(getSuccessEdit);
  const [width, setWidth] = useState(window.innerWidth);
  const studio = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoader = useSelector(getPlanningLoadingEdit);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    dispatch(clearError());
    if (openPlanningEdit) {
      formEditPlanning.setFields([
        {
          name: "target",
          value: plan?.plan || 0,
        },
      ]);
    }
  }, [formEditPlanning, openPlanningEdit]);

  return (
    <Drawer
      styles={{
        body: { padding: 0 },
      }}
      open={openPlanningEdit}
      onCancel={() => {
        dispatch(clearError());
        dispatch(clearSuccessEdit());
        dispatch(setOpenPlanningEdit());
      }}
      onClose={() => {
        dispatch(clearError());
        dispatch(clearSuccessEdit());
        dispatch(setOpenPlanningEdit());
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Месячный план
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      {isLoader && <LoaderModal />}
      {openSuccessEdit ? (
        <SuccessModalSide
          close={() => {
            dispatch(clearError());
            dispatch(clearSuccessEdit());
            dispatch(setOpenPlanningEdit());
          }}
          title="Месячный план успешно добавлен!"
        />
      ) : (
        <div
          style={{
            padding: width > 640 ? "0 64px 20px" : "0 24px 20px",
          }}
        >
          <Form
            style={{ marginTop: "20px" }}
            layout="vertical"
            form={formEditPlanning}
            onFinish={(data) => {
              data.studio = studio?.id;
              data.date_from = convertDateToISO861(dateAfter);
              data.date_to = dateBefore;

              dispatch(
                patchPlanning({
                  studio_y_id: studio?.y_id,
                  id: plan?.plan_id,
                  planning: data,
                })
              ).then((state) => {
                if (state?.payload?.errors) {
                  throwFormError(formEditPlanning, state?.payload);
                }
              });
            }}
          >
            {error && displayErrors(error)}

            <Form.Item
              name="target"
              label="Сумма"
              rules={[
                {
                  type: "number",
                  min: 1000,
                  message: "Сумма не может быть меньше 1000",
                },
              ]}
            >
              <InputNumber
                placeholder="Введите сумму"
                style={{ borderRadius: 2, width: "100%" }}
              />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                <Button
                  style={{ width: "100%", borderRadius: 2 }}
                  onClick={() => {
                    dispatch(setOpenPlanningEdit());
                    dispatch(clearSuccessEdit());
                    dispatch(clearError());
                  }}
                >
                  Отмена
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#76CEC9",
                  }}
                  htmlType="submit"
                  type="primary"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
