export const getPaymentsData = ({ payments }) => payments.payments;
export const getPaymentsLoading = ({ payments }) => payments.loaders.common;
export const getPaymentsLoadingPost = ({ payments }) => payments.loaders.post;
export const getErrorEdits = ({ payments }) => payments.errors.edit;
export const getErrorCreate = ({ payments }) => payments.errors.create;
export const getErrorDelete = ({ payments }) => payments.errors.delete;
export const getError = ({ payments }) => payments.errors.error;
export const getSuccess = ({ payments }) => payments.success.success;
export const getSuccessCreate = ({ payments }) => payments.success.create;
export const getPaymentsDropDownSelector = ({ payments }) =>
  payments.paymentsDropDown;
export const getOpenPaymentsSelector = ({ payments }) => payments.openPayments;
export const getPaymentsNextPage = ({ payments }) => payments.nextPage;

export const getPaymentsLoadingCheck = ({ payments }) => payments.loaders.check;
export const getSuccessCheck = ({ payments }) => payments.success.check;
export const getErrorCheck = ({ payments }) => payments.errors.check;
export const getCheck = ({ payments }) => payments.paymentsCheck;
