import { createColumn } from "../../utils/utils";
import Help from "../../images/iconInfo.svg";
import { Space, Avatar, Checkbox, Tooltip } from "antd";
import styles from "./styles.module.css";

export const columns = ({
  isOpenCalculationPremiums,
  calculationData,
  isCheckedAll,
  setIsCheckedAll,
  setCheckedRows,
  checkedRows,
}) => {
  return [
    createColumn(
      () => {
        const hasTrueRow = calculationData?.results?.some((row) => row.check);

        return (
          isOpenCalculationPremiums &&
          hasTrueRow && (
            <Checkbox
              checked={isCheckedAll}
              onChange={(e) => {
                e.stopPropagation();
                setIsCheckedAll(e.target.checked);
                if (e.target.checked) {
                  const visibleRows = calculationData?.results?.filter(
                    (row) => row.check
                  );
                  setCheckedRows(visibleRows.map((row) => row));
                } else {
                  setCheckedRows([]);
                }
              }}
            />
          )
        );
      },
      "checkbox",
      (_, row) => {
        const isChecked = checkedRows.includes(row);
        return (
          isOpenCalculationPremiums &&
          row.check && (
            <Checkbox
              checked={isChecked}
              onChange={(evt) => {
                evt.stopPropagation();
                let newCheckedRows;

                if (!isChecked) {
                  newCheckedRows = [...checkedRows, row];
                } else {
                  newCheckedRows = checkedRows.filter(
                    (item) => item.master.id !== row.master.id
                  );
                }
                setCheckedRows(newCheckedRows);

                const visibleRows = calculationData?.results?.filter(
                  (row) => row.check
                );
                const isAllVisibleChecked =
                  newCheckedRows.length === visibleRows.length;

                setIsCheckedAll(isAllVisibleChecked);
              }}
            />
          )
        );
      },
      30,
      "center"
    ),
    createColumn(
      "Сотрудник",
      "master",
      (v) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={v?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{v?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  maxWidth: "170px",
                }}
              >
                {v?.position?.y_title}
              </span>
            </Space>
          </div>
        );
      },
      50
    ),
    createColumn(
      "Схема премирования",
      "schemes",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {v?.map((item) => (
              <span key={item?.id} style={{ whiteSpace: "nowrap" }}>
                {item?.scheme?.name}
              </span>
            ))}
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Сумма",
      "total",
      (v, row) => {
        return (
          (v || v === 0) && (
            <Space style={{ gap: 12 }}>
              <span
                style={{ textWrap: "nowrap", fontWeight: 700 }}
              >{`${v.toLocaleString("ru-RU")} р.`}</span>
              {row?.comment?.length > 0 && (
                <Tooltip
                  color="#FFFFFF"
                  title={
                    <span style={{ color: "#4B4F55" }}>
                      {row?.comment?.map((item) => {
                        return (
                          <Space direction="vertical">
                            <span style={{ fontWeight: 700 }}>
                              Название схемы:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {item?.scheme_name}
                              </span>
                            </span>

                            {item?.details?.map((item) => {
                              return (
                                <>
                                  <span span style={{ fontWeight: 700 }}>
                                    Размер бонуса:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item?.bonus?.toLocaleString("ru-RU")} p.
                                    </span>
                                  </span>
                                  <span span style={{ fontWeight: 700 }}>
                                    Тип бонуса:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item?.bonus_type}
                                    </span>
                                  </span>
                                  <span span style={{ fontWeight: 700 }}>
                                    Фиксированный бонус:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item?.fix_bonus?.toLocaleString("ru-RU")}{" "}
                                      р.
                                    </span>
                                  </span>
                                  <span span style={{ fontWeight: 700 }}>
                                    Процент бонуса:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item?.persent_bonus} %
                                    </span>
                                  </span>
                                  <span span style={{ fontWeight: 700 }}>
                                    Доход от услуг:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item?.service_revenue?.toLocaleString(
                                        "ru-RU"
                                      )}{" "}
                                      p.
                                    </span>
                                  </span>
                                  <span span style={{ fontWeight: 700 }}>
                                    Фактический доход:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                      {item?.fact_rev?.toLocaleString("ru-RU")}{" "}
                                      p.
                                    </span>
                                  </span>
                                </>
                              );
                            })}
                          </Space>
                        );
                      })}
                    </span>
                  }
                >
                  <img src={Help} alt="info" className={styles.iconHelp} />
                </Tooltip>
              )}
            </Space>
          )
        );
      },
      100
    ),
    createColumn(
      "Созданные премии",
      "inform",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {v?.map((item, index) => (
              <div key={index}>
                <span style={{ whiteSpace: "nowrap", fontWeight: 700 }}>
                  {item?.amount?.toLocaleString("ru-RU")} p.
                </span>
                <span
                  style={{
                    fontSize: 12,
                    lineHeight: "14.52px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    maxWidth: "170px",
                  }}
                >
                  {formatPeriod(item?.period)}
                </span>
              </div>
            ))}
          </Space>
        );
      },
      100
    ),
  ];
};

export function formatPeriod(period) {
  if (!period) return "";

  return period
    .split(" - ")
    .map((date) => {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    })
    .join(" - ");
}
