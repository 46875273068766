import { createColumn, dateToWeekdayAndWeekNumber } from "../../utils/utils";
import { Tooltip } from "antd";

export const columns = () => {
  return [
    createColumn(
      "День",
      "day",
      (_, row) => {
        return row && dateToWeekdayAndWeekNumber(row);
      },
      100
    ),
    createColumn(
      "Выполнение факт",
      "smart_plan_persent",
      (v, row) => {
        return (
          v !== undefined &&
          v !== null && (
            <div
              style={{
                backgroundColor: `#${row?.smart_plan_persent_color}`,
                width: 53,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                fontWeight: 700,
              }}
            >
              {v} %
            </div>
          )
        );
      },
      100
    ),
    createColumn(
      "Выполнение прогноз",
      "smart_plan_exp",
      (v, row) => {
        return (
          v !== undefined &&
          v !== null && (
            <div
              style={{
                backgroundColor: `#${row?.smart_plan_exp_color}`,
                width: 53,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                fontWeight: 700,
              }}
            >
              {v} %
            </div>
          )
        );
      },
      100
    ),
    createColumn(
      "Дневной план",
      "smart_plan",
      (v) => {
        return v && `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Динамический план",
      "dyno_plan",
      (v) => {
        return v && `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Дневной факт",
      "day_result",
      (v) => {
        return v && `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Прогноз",
      "expectations",
      (v) => {
        return v && `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Созданные записи",
      "day_forecast",
      (v) => {
        return `${v?.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Количество полных смен",
      "slots",
      (v) => {
        return Math.round(v * 10) / 10;
      },
      100
    ),
    createColumn(
      "Разбивка по специализациям",
      "specialisation",
      (v) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            {v.map((item, index) => {
              return (
                <Tooltip
                  color="#FFFFFF"
                  title={<span style={{ color: "#4B4F55" }}>{item.name}</span>}
                  key={index}
                >
                  <div
                    style={{
                      backgroundColor: "#7ADA9D33",
                      padding: "4px 14px",
                      borderRadius: 5,
                      width: 156,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    {item.name}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        );
      },
      200
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Прогноз</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item, index) => {
              return (
                <span key={index}>
                  {item?.summ_expectation
                    ? item?.summ_expectation?.toLocaleString("ru-RU") + " р."
                    : ""}
                </span>
              );
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Факт</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item, index) => {
              return (
                <span key={index}>
                  {item?.sum_fact?.toLocaleString("ru-RU") || 0} р.
                </span>
              );
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Смены</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item, index) => {
              return (
                <span key={index}>
                  {Math.round(item.sum_slots * 10) / 10 || 0}
                </span>
              );
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      <p style={{ color: "#777676", margin: 0 }}>Заполненность</p>,
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            {v.map((item, index) => {
              return <span key={index}>{item.fillness} %</span>;
            })}
          </div>
        );
      },
      100
    ),
  ];
};
