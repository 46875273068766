import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const getPlanning = createAsyncThunk(
  "getPlanning",
  async ({ studio_y_id, date }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `studio_forecast/studio/${studio_y_id}/forecast/`,
        {
          params: date,
        }
      );
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const postPlanning = createAsyncThunk(
  "postPlanning",
  async ({ studio_y_id, planning }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_target/studio/${studio_y_id}/target/`,
        planning
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const patchPlanning = createAsyncThunk(
  "patchPlanning",
  async ({ studio_y_id, id, planning }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(
        `/studio_target/studio/${studio_y_id}/target/${id}/`,
        planning
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  planning: null,
  openPlanningCreate: false,
  openPlanningEdit: false,
  success: {
    success: false,
    create: false,
    edit: false,
  },
  errors: null,
  loaders: {
    common: false,
    create: false,
    edit: false,
  },
};

const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    setOpenPlanningCreate: (state) => {
      state.openPlanningCreate = !state.openPlanningCreate;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
    setOpenPlanningEdit: (state) => {
      state.openPlanningEdit = !state.openPlanningEdit;
    },
    clearSuccessEdit: (state) => {
      state.success.edit = false;
    },
  },

  extraReducers: (builder) => {
    // getPlanning
    builder.addCase(getPlanning.pending, (state) => {
      state.loaders.common = true;
      state.errors = null;
    });
    builder.addCase(getPlanning.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.planning = payload;
    });
    builder.addCase(getPlanning.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors = action?.payload?.data;
    });
    // postPlanning
    builder.addCase(postPlanning.pending, (state) => {
      state.loaders.create = true;
      state.errors = null;
    });
    builder.addCase(postPlanning.fulfilled, (state) => {
      state.loaders.create = false;
      state.success.create = true;
    });
    builder.addCase(postPlanning.rejected, (state, action) => {
      state.loaders.create = false;
      state.errors = action?.payload?.data;
    });
    // patchPlanning
    builder.addCase(patchPlanning.pending, (state) => {
      state.loaders.edit = true;
      state.errors = null;
    });
    builder.addCase(patchPlanning.fulfilled, (state) => {
      state.loaders.edit = false;
      state.success.edit = true;
    });
    builder.addCase(patchPlanning.rejected, (state, action) => {
      state.loaders.edit = false;
      state.errors = action?.payload?.data;
    });
  },
});
export const {
  clearError,
  clearSuccess,
  setOpenPlanningCreate,
  setOpenPlanningEdit,
  clearSuccessCreate,
  clearSuccessEdit,
} = planningSlice.actions;
export default planningSlice.reducer;
