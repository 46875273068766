export const getPlanningData = ({ planning }) => planning.planning;
export const getPlanningLoading = ({ planning }) => planning.loaders.common;
export const getError = ({ planning }) => planning.errors;
export const getOpenPlanningCreateSelector = ({ planning }) =>
  planning.openPlanningCreate;
export const getSuccessCreate = ({ planning }) => planning.success.create;
export const getPlanningLoadingCreate = ({ planning }) =>
  planning.loaders.create;
export const getOpenPlanningEditSelector = ({ planning }) =>
  planning.openPlanningEdit;
export const getSuccessEdit = ({ planning }) => planning.success.edit;
export const getPlanningLoadingEdit = ({ planning }) => planning.loaders.edit;
