import { createColumn } from "../../utils/utils";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import styles from "./styles.module.css";

export const columns = ({ setOpenAwardSchemesDelete }) => {
  return [
    createColumn(
      "Схемы премирования в студии",
      "name",
      (v) => {
        return <span style={{ color: "#4B4F55" }}>{v}</span>;
      },
      450
    ),
    // createColumn(
    //   "",
    //   "critical_level",
    //   (v) => {
    //     return <span>{v}</span>;
    //   },
    //   450
    // ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={
              <span style={{ color: "#4B4F55" }}>Удалить схему премии</span>
            }
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenAwardSchemesDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      20
    ),
  ];
};
