import React from "react";
import { Spin } from "antd";

export const LoaderModal = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(118, 206, 201, 0.2)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "absolute",
        zIndex: 1011,
      }}
    >
      <Spin />
    </div>
  );
};
