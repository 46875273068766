import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Row, Col, Tag, Tooltip } from "antd";
import { createColumn, translateWord } from "../../utils/utils";
import styles from "./styles.module.css";

export const columns = ({ setOpenUserManagementDelete }) => {
  return [
    createColumn(
      "Имя",
      "y_name",
      (v, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.y_avatar}
            />
            <span>{v}</span>
          </div>
        );
      },
      70
      // "center"
    ),
    createColumn(
      "Контакты",
      "y_phone",
      (_, v) => {
        return (
          <Row style={{ flexDirection: "column", justifyContent: "center" }}>
            <Col style={{ fontSize: "12px" }}>
              {v?.y_phone.slice(0, 3) + "****" + v?.y_phone.slice(-4)}
            </Col>
            <Col style={{ fontSize: "12px" }}>{v?.y_email}</Col>
          </Row>
        );
      },
      70
      // "center"
    ),
    createColumn(
      "Роль пользователя",
      "role",
      (v) => {
        return (
          <Tag
            color={
              {
                worker: "#ffe7ba",
                administrator: "#bae0ff",
                manager: "#b5f5ec",
                owner: "#d9f7be",
                system: "#ffccc7",
              }[v]
            }
            style={{
              fontSize: 12,
              lineHeight: "16px",
              textAlign: "center",
              textTransform: "uppercase",
              margin: 0,
              color: {
                worker: "#d46b08",
                administrator: "#0958d9",
                manager: "#08979c",
                owner: "#389e0d",
                system: "#cf1322",
              }[v],
            }}
          >
            {translateWord(v)}
          </Tag>
        );
      },
      70
      // "center"
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={
              <span style={{ color: "#4B4F55" }}>Удалить пользователя</span>
            }
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenUserManagementDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      100,
      "right"
    ),
  ];
};
