import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const postBonusSchemesNetwork = createAsyncThunk(
  "postBonusSchemesNetwork",
  async ({ network_y_id, schemes }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/network_master_bonus_schemes/network/${network_y_id}/schemes/`,
        schemes
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBonusSchemesNetwork = createAsyncThunk(
  "deleteBonusSchemesNetwork",
  async ({ network_y_id, id }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/network_master_bonus_schemes/network/${network_y_id}/schemes/${id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  awardSchemesMasterNetwork: null,
  success: {
    success: false,
    delete: false,
    post: false,
  },
  errors: {
    error: null,
    delete: null,
    post: null,
  },
  loaders: {
    common: false,
    delete: false,
    post: false,
  },
};

const awardSchemesMasterNetworkSlice = createSlice({
  name: "awardSchemesMasterNetwork",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearErrorDelete: (state) => {
      state.errors.delete = null;
    },
    clearErrorPost: (state) => {
      state.errors.post = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearSuccessBonusSchemesDelete: (state) => {
      state.success.delete = false;
    },
    clearSuccessBonusSchemesPost: (state) => {
      state.success.post = false;
    },
  },

  extraReducers: (builder) => {
    // postBonusSchemesNetwork
    builder.addCase(postBonusSchemesNetwork.pending, (state) => {
      state.loaders.post = true;
      state.errors.post = null;
    });
    builder.addCase(postBonusSchemesNetwork.fulfilled, (state) => {
      state.loaders.post = false;
      state.success.post = true;
    });
    builder.addCase(postBonusSchemesNetwork.rejected, (state, action) => {
      state.loaders.post = false;
      state.errors.post = action?.payload?.data;
    });
    // deleteBonusSchemesNetwork
    builder.addCase(deleteBonusSchemesNetwork.pending, (state) => {
      state.loaders.delete = true;
      state.errors.delete = null;
    });
    builder.addCase(deleteBonusSchemesNetwork.fulfilled, (state) => {
      state.loaders.delete = false;
      state.success.delete = true;
    });
    builder.addCase(deleteBonusSchemesNetwork.rejected, (state, action) => {
      state.loaders.delete = false;
      state.errors.delete = action?.payload;
    });
  },
});
export const {
  clearError,
  clearErrorDelete,
  clearErrorPost,
  clearSuccess,
  clearSuccessBonusSchemesDelete,
  clearSuccessBonusSchemesPost,
} = awardSchemesMasterNetworkSlice.actions;
export default awardSchemesMasterNetworkSlice.reducer;
