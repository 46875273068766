import { createColumn, convertToLocalDate } from "../../utils/utils";
import { Space, Tooltip, Avatar, Checkbox } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import styles from "./styles.module.css";

export const y_client = [
  { name: "Внесено", bol: true },
  { name: "Не внесено", bol: false },
];

export const columns = ({
  paymentsData,
  isOpenPayments,
  isCheckedAll,
  setIsCheckedAll,
  checkedRows,
  setCheckedRows,
  setOpenPaymentsDelete,
}) => {
  return [
    createColumn(
      () => {
        const hasTrueRow = paymentsData?.data?.results?.some(
          (row) => row.is_post_yclient === false
        );
        return (
          isOpenPayments &&
          hasTrueRow && (
            <Checkbox
              checked={isCheckedAll}
              onChange={(e) => {
                e.stopPropagation();
                setIsCheckedAll(e.target.checked);
                if (e.target.checked) {
                  const visibleRows = paymentsData?.data?.results.filter(
                    (row) => !row.is_post_yclient
                  );
                  setCheckedRows(visibleRows.map((row) => row));
                } else {
                  setCheckedRows([]);
                }
              }}
            />
          )
        );
      },
      "checkbox",
      (_, row) => {
        const isChecked = checkedRows.includes(row);
        return (
          isOpenPayments &&
          !row.is_post_yclient && (
            <Checkbox
              checked={isChecked}
              onChange={(evt) => {
                evt.stopPropagation();
                let newCheckedRows;
                if (!isChecked) {
                  newCheckedRows = [...checkedRows, row];
                } else {
                  newCheckedRows = checkedRows.filter(
                    (item) => item.id !== row.id
                  );
                }
                setCheckedRows(newCheckedRows);

                const visibleRows = paymentsData?.data?.results.filter(
                  (row) => !row.is_post_yclient
                );
                const isAllVisibleChecked =
                  newCheckedRows.length === visibleRows.length;

                setIsCheckedAll(isAllVisibleChecked);
              }}
            />
          )
        );
      },
      30,
      "center"
    ),
    createColumn(
      "Id выплаты",
      "id",
      (v) => {
        return <span style={{ color: "#4B4F55" }}>{v}</span>;
      },
      70
    ),
    createColumn(
      "Период расчета",
      null,
      (_, row) => {
        let valueStart = convertToLocalDate(row?.period_start_date);
        let valueEnd = convertToLocalDate(row?.period_end_date);
        const [dateStart] = valueStart.split(" ");
        const [dateEnd] = valueEnd.split(" ");
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
              с {dateStart}
            </span>
            <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
              по {dateEnd}
            </span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Mастер",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Дата выплаты",
      "pay_date_time",
      (_, row) => {
        let valueStart = convertToLocalDate(row?.pay_date_time);
        let valueEnd = convertToLocalDate(row?.pay_date_time_yclient);

        const [dateStart] = valueStart ? valueStart.split(" ") : [null];
        const [dateEnd] = valueEnd ? valueEnd.split(" ") : [null];
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {dateStart && (
              <div style={{ display: "flex", gap: 5 }}>
                <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
                  по glamguru:
                </span>
                <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
                  {dateStart}
                </span>
              </div>
            )}
            {dateEnd && (
              <div style={{ display: "flex", gap: 5 }}>
                <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
                  от Yclients:
                </span>
                <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
                  {dateEnd}
                </span>
              </div>
            )}
          </Space>
        );
      },
      200
    ),
    createColumn(
      "Сумма",
      "paid",
      (v) => {
        return <span>{v}</span>;
      },
      100
    ),
    createColumn(
      "Коррекции в выплате",
      "related_corrections",
      (v) => {
        return v.length > 0 ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "Добавлено в YClients",
      "is_post_yclient",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить выплату</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenPaymentsDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      50
    ),
  ];
};
