import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./login";
import userSlice from "./user";
import failsSlice from "./fails";
import studiosSlice from "./studios";
import clientsSlice from "./clients";
import staffSlice from "./staff";
import correctionsSlice from "./corrections";
import networksSlice from "./networks";
import networkComplaintsSlice from "./networkComplaints";
import networkCorrectionsSlice from "./networkCorrections";
import userManagementSlice from "./userManagement";
import paymentsSlice from "./payments";
import typeSlice from "./typeComplaints";
import calculationSlice from "./calculation";
import calculationNetworkSlice from "./networkCalculation";
import settingsAdminSlice from "./settingsAdmin";
import settingsPlanningSlice from "./settingsPlanning";
import planningSlice from "./planning";
import expensesSlice from "./expenses";
import awardSchemesSlice from "./awardSchemes";
import awardSchemesNetworkSlice from "./awardSchemesNetwork";
import awardSchemesMasterSlice from "./awardSchemesMaster";
import awardSchemesMasterNetworkSlice from "./awardSchemesMasterNetwork";
import calculationPremiumsSlice from "./calculationPremiums";
import calculationPremiumsNetworkSlice from "./calculationPremiumsNetwork";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  fails: failsSlice,
  studios: studiosSlice,
  clients: clientsSlice,
  staff: staffSlice,
  corrections: correctionsSlice,
  networks: networksSlice,
  networkComplaints: networkComplaintsSlice,
  networkCorrections: networkCorrectionsSlice,
  userManagement: userManagementSlice,
  payments: paymentsSlice,
  type: typeSlice,
  calculation: calculationSlice,
  calculationNetwork: calculationNetworkSlice,
  settingsAdmin: settingsAdminSlice,
  settingsPlanning: settingsPlanningSlice,
  planning: planningSlice,
  expenses: expensesSlice,
  awardSchemes: awardSchemesSlice,
  awardSchemesNetwork: awardSchemesNetworkSlice,
  awardSchemesMaster: awardSchemesMasterSlice,
  awardSchemesMasterNetwork: awardSchemesMasterNetworkSlice,
  calculationPremiums: calculationPremiumsSlice,
  calculationPremiumsNetwork: calculationPremiumsNetworkSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
