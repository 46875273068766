import { useState, useEffect } from "react";
import {
  Form,
  Upload,
  Image,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Typography,
  Row,
  Col,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPositionDropDown,
  postStaff,
  clearErrorCreate,
} from "../../store/staff";
import {
  getPositionDataSelector,
  getErrorCreate,
  getStaffLoadingPost,
} from "../../store/staff/selectors";
import { getUsersStudioDropDown } from "../../store/studios";
import {
  getStudioData,
  getUsersStudioDropDownSelectors,
} from "../../store/studios/selectors";
import { reversFormatDateString } from "../../utils/utils";
import displayErrors from "../../utils/displayErrors";
import { uploadButton } from "../../utils/uploadButton";
import styles from "./styles.module.css";
import { LoaderModal } from "../../components/LoaderModal";

export const StaffCreatePage = () => {
  const [formCreateStaff] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(getErrorCreate);
  const isLoader = useSelector(getStaffLoadingPost);
  const [imageUrl, setImageUrl] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const positionList = useSelector(getPositionDataSelector);
  const dataUsers = useSelector(getUsersStudioDropDownSelectors);
  const studioData = useSelector(getStudioData);
  const [isCheckedFamiliar, setIsCheckedFamiliar] = useState(false);
  const [isCheckedTesting, setIsCheckedTesting] = useState(false);

  const onFamiliarChange = (e) => {
    setIsCheckedFamiliar(e.target.checked);
  };
  const onTestingChange = (e) => {
    setIsCheckedTesting(e.target.checked);
  };

  const handleChange = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      setImageUrl(URL.createObjectURL(file));
      setImageFile(file);
    } else {
      setImageUrl(null);
      setImageFile(null);
    }
  };

  useEffect(() => {
    dispatch(clearErrorCreate());
  }, []);

  useEffect(() => {
    dispatch(getUsersStudioDropDown(studioData?.y_id));
  }, [studioData?.y_id]);

  useEffect(() => {
    if (studioData) {
      dispatch(getPositionDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  return (
    <div>
      {isLoader && <LoaderModal />}
      <Form
        layout="vertical"
        form={formCreateStaff}
        onFinish={(data) => {
          let formData = new FormData();

          data.y_avatar =
            "https://cdnn21.img.ria.ru/images/154976/79/1549767987_0:158:3079:1890_1920x1080_80_0_0_862ffcefa595ff9edef2828fbc11daf1.jpg";
          data.birth_date = reversFormatDateString(
            data?.birth_date?.$d?.toLocaleDateString("ru-RU")
          );
          data.form_replacement_date = reversFormatDateString(
            data?.form_replacement_date?.$d?.toLocaleDateString("ru-RU")
          );

          data.hiring_date = reversFormatDateString(
            data?.hiring_date?.$d.toLocaleDateString("ru-RU")
          );
          data.medical_book_update_date = reversFormatDateString(
            data?.medical_book_update_date?.$d?.toLocaleDateString("ru-RU")
          );

          data.familiar_with_rules = isCheckedFamiliar;
          data.passed_testing = isCheckedTesting;
          data.studio = studioData?.id;

          for (let key of Object.keys(data)) {
            if (data[key] !== undefined && data[key] !== "") {
              formData.append(`${key}`, data[key]);
            }
          }

          dispatch(clearErrorCreate());
          dispatch(
            postStaff({ studio_y_id: studioData?.y_id, staff: formData })
          ).then((state) => {
            if (!state?.payload?.errors) {
              navigate(`/staff/${studioData?.y_id}`);
            }
          });
        }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 34,
          overflowY: "auto",
          margin: 0,
          height: "calc(100vh - 140px)",
        }}
      >
        {error && displayErrors(error)}
        <Row
          gutter={16}
          style={{
            gap: 34,
            width: "100%",
            flexWrap: "wrap",
            justifyContent: width < 1210 ? "center" : "space-between",
          }}
        >
          <Col
            style={{
              padding: "19px 23px",
              backgroundColor: "#FFF",
              flex: 1,
              minWidth: 320,
            }}
          >
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Upload
                listType="picture-circle"
                showUploadList={false}
                beforeUpload={() => false}
                accept=".jpeg,.png,.svg+xml,.jpg"
                onChange={handleChange}
                maxCount={1}
              >
                {imageUrl ? (
                  <Image
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: 193,
                      borderRadius: "50%",
                      height: 193,
                    }}
                    onClick={(evt) => evt.stopPropagation()}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              name="y_name"
              label="Имя"
              rules={[
                {
                  required: true,
                  message: `Необходимо написать имя`,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input placeholder="Введите имя" style={{ borderRadius: 2 }} />
            </Form.Item>

            <Form.Item
              name="position"
              rules={[
                {
                  required: true,
                  message: "Необходимо выбрать должность",
                },
              ]}
              label="Должность"
              style={{ width: "100%" }}
            >
              <Select
                style={{ borderRadius: 2 }}
                options={positionList?.map((option) => {
                  return {
                    value: option.pk,
                    label: option.y_title,
                  };
                })}
                placeholder="Выберите должность"
              />
            </Form.Item>
          </Col>
          <Col
            style={{
              padding: "19px 23px",
              backgroundColor: "#FFF",
              flex: 1,
              minWidth: 320,
            }}
          >
            <Form.Item
              name="user"
              label="Пользователь"
              style={{ width: "100%" }}
            >
              <Select
                style={{ borderRadius: 2 }}
                options={dataUsers?.map((option) => {
                  return {
                    value: option.id,
                    label:
                      option?.external_data?.y_name +
                      " - " +
                      option?.external_data?.y_phone,
                  };
                })}
                placeholder="Выберите пользователя"
              />
            </Form.Item>

            <Form.Item
              name="hiring_date"
              label="Дата найма в филиал"
              rules={[
                {
                  required: true,
                  message: "Необходимо выбрать дату",
                },
              ]}
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD.MM.YYYY"
                cellWidth={10}
                timeColumnWidth={10}
                presetsMaxWidth={10}
                style={{ width: "100%", borderRadius: 2 }}
                placeholder="Выберите дату"
              />
            </Form.Item>

            <Form.Item
              name="birth_date"
              label="Дата рождения"
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD.MM.YYYY"
                cellWidth={10}
                timeColumnWidth={10}
                presetsMaxWidth={10}
                style={{ width: "100%", borderRadius: 2 }}
                placeholder="Выберите дату"
              />
            </Form.Item>

            <Form.Item
              name="medical_book_update_date"
              label="Дата обновления медкнижки"
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD.MM.YYYY"
                cellWidth={10}
                timeColumnWidth={10}
                presetsMaxWidth={10}
                style={{ width: "100%", borderRadius: 2 }}
                placeholder="Выберите дату"
              />
            </Form.Item>

            <Form.Item
              name="form_replacement_date"
              label="Дата обновления формы"
              style={{ width: "100%" }}
            >
              <DatePicker
                format="DD.MM.YYYY"
                cellWidth={10}
                timeColumnWidth={10}
                presetsMaxWidth={10}
                style={{ width: "100%", borderRadius: 2 }}
                placeholder="Выберите дату"
              />
            </Form.Item>

            <Form.Item
              name="salary"
              label="Оклад"
              rules={[
                {
                  required: true,
                  message: `Необходимо написать оклад`,
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input placeholder="Введите оклад" style={{ borderRadius: 2 }} />
            </Form.Item>

            <Form.Item
              name="familiar_with_rules"
              label=""
              style={{ width: "100%" }}
            >
              <div style={{ display: "flex", gap: 17 }}>
                <Checkbox
                  checked={isCheckedFamiliar}
                  onChange={onFamiliarChange}
                />
                <Typography.Text>Ознакомлен с правилами</Typography.Text>
              </div>
            </Form.Item>

            <Form.Item name="passed_testing" label="" style={{ width: "100%" }}>
              <div style={{ display: "flex", gap: 17 }}>
                <Checkbox
                  checked={isCheckedTesting}
                  onChange={onTestingChange}
                />
                <Typography.Text>Прошел тестирование</Typography.Text>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#4D7CD6" }}
            className={styles.button}
          >
            {width > 640 && (
              <span className={styles.button_text}>Сохранить</span>
            )}
          </Button>
        </Row>
      </Form>
    </div>
  );
};
