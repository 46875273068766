import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

export const getUser = createAsyncThunk(
  "getUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/user-context/data/");
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  user: null,
  errors: null,
  openSidebar: null,
  loaders: {
    common: false,
    password: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setClearUser: (state) => {
      state.user = null;
    },
    setOpenSidebar: (state, action) => {
      state.openSidebar = action.payload;
    },
    setOpenInfo: (state) => {
      state.openInfo = !state.openInfo;
    },
  },

  extraReducers: (builder) => {
    // getUser
    builder.addCase(getUser.pending, (state) => {
      state.errors = null;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.errors = action?.payload?.data;
    });
  },
});
export const { setOpenInfo, setOpenSidebar, setClearUser } = userSlice.actions;
export default userSlice.reducer;
