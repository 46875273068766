import { createColumn } from "../../utils/utils";
import { Space, Avatar, Row, Col, Tooltip } from "antd";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { convertToLocalDate, getDaysWord } from "../../utils/utils";
import styles from "./styles.module.css";

export const guarantee = [
  { name: "Гарантийный случай", bol: true },
  { name: "Негарантийный случай", bol: false },
];

export const status = [
  { name: "Клиент вернулся", bol: true },
  { name: "Клиент не вернулся", bol: false },
];

export const columns = () => {
  return [
    createColumn(
      "Студия",
      "studio",
      (v) => {
        return <span>{v?.y_title}</span>;
      },
      100
    ),
    createColumn(
      "Дата и время",
      "request_datetime",
      (v) => {
        let value = convertToLocalDate(v);
        const [date, time] = value.split(" ");
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ color: "#4B4F55" }}>{date}</span>
            <span style={{ color: "#7A7D86" }}>{time}</span>
          </Space>
        );
      },
      70
    ),
    createColumn(
      "Клиент",
      "client",
      (v) => {
        return (
          <Row>
            <Col style={{ fontSize: "12px" }}>{v?.y_name}</Col>
            <Col style={{ fontSize: "12px" }}>
              {v?.y_phone.slice(0, 3) + "****" + v?.y_phone.slice(-4)}
            </Col>
          </Row>
        );
      },
      100
    ),
    createColumn(
      "Mастера",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Тип",
      "fail_type",
      (v) => {
        return v.map((item) => {
          return (
            <Row key={item?.id}>
              <Col style={{ fontSize: "12px", textWrap: "nowrap" }}>
                {item?.name}
              </Col>
            </Row>
          );
        });
      },
      100
    ),
    createColumn(
      "Прошло дней",
      "created_at",
      (v) => {
        const createdAt = new Date(v);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - createdAt);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
          <span>
            {diffDays} {getDaysWord(diffDays)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Комментарий",
      "comment",
      (v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>{v}</span>}
          >
            <p className={styles.commentText}>{v}</p>
          </Tooltip>
        );
      },
      100
    ),
    createColumn(
      "Записали",
      "repair_record",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "Фото",
      "photos",
      (_, v) => {
        return v?.photos?.length > 0 ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "Вычет из ЗП мастера",
      "repair_cost",
      (v) => {
        if (v) {
          return <p style={{ textWrap: "nowrap" }}>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Сумма скидки из ЗП мастера",
      "loyal_cost",
      (v) => {
        if (v) {
          return <p style={{ textWrap: "nowrap" }}>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Клиент вернулся",
      "is_returned",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
  ];
};
