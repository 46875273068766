import { useState, useEffect } from "react";
import {
  Form,
  Checkbox,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  Drawer,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import { LoaderModal } from "../LoaderModal";
import { SuccessModalSide } from "../SuccessModalSide";
import {
  postBonusSchemesNetwork,
  clearErrorPost,
  clearSuccessBonusSchemesPost,
} from "../../store/awardSchemesMasterNetwork";
import {
  getErrorBonusSchemesPost,
  getAwardSchemesLoadingPost,
  getSuccessPost,
} from "../../store/awardSchemesMasterNetwork/selectors";
import { getAwardSchemesNetwork } from "../../store/awardSchemesNetwork";
import { getAwardSchemesData } from "../../store/awardSchemesNetwork/selectors";
import { convertDateISO } from "../../utils/utils";
import styles from "./styles.module.css";

export const BonusSchemesNetworkModal = ({
  openBonusSchemes,
  setOpenBonusSchemes,
}) => {
  const [formBonusSchemes] = Form.useForm();
  const dispatch = useDispatch();
  const bonusSchemesList = useSelector(getAwardSchemesData);
  const error = useSelector(getErrorBonusSchemesPost);
  const isLoader = useSelector(getAwardSchemesLoadingPost);
  const openSuccess = useSelector(getSuccessPost);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleCheckboxChange = (itemId, evt) => {
    const checked = evt.target.checked;

    setSelectedCheckboxes((prev) => {
      const newSelected = prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId];

      return newSelected;
    });
    if (!checked) {
      formBonusSchemes.resetFields([`beginnings_${itemId}`]);
      formBonusSchemes.resetFields([`completions_${itemId}`]);
    }
  };

  useEffect(() => {
    dispatch(clearErrorPost());
    dispatch(
      getAwardSchemesNetwork({
        network_y_id: JSON.parse(sessionStorage.getItem("studio"))?.y_id,
        // pagination: false,
      })
    );
  }, [openBonusSchemes]);

  return (
    <Drawer
      styles={{
        body: { padding: 0 },
      }}
      open={openBonusSchemes?.open}
      onCancel={() => {
        dispatch(clearErrorPost());
        setOpenBonusSchemes({ open: false });
        dispatch(clearSuccessBonusSchemesPost());
      }}
      onClose={() => {
        dispatch(clearErrorPost());
        setOpenBonusSchemes({ open: false });
        dispatch(clearSuccessBonusSchemesPost());
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Добавить схему
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      {isLoader && <LoaderModal />}
      {openSuccess ? (
        <SuccessModalSide
          close={() => {
            dispatch(clearErrorPost());
            setOpenBonusSchemes({ open: false });
            dispatch(clearSuccessBonusSchemesPost());
          }}
          title="Схема успешно добавлена!"
        />
      ) : (
        <div
          style={{
            padding: "0 18px",
          }}
        >
          <Form
            style={{ marginTop: "20px" }}
            layout="vertical"
            form={formBonusSchemes}
            onFinish={(data) => {
              const schemesArray = bonusSchemesList?.data?.results
                ?.filter((item) => selectedCheckboxes.includes(item.id))
                .map((item) => {
                  const studioId = item.id;
                  const startDate = data[`beginnings_${studioId}`];
                  const endDate = data[`completions_${studioId}`];

                  if (!startDate || !endDate) {
                    return null;
                  }

                  const resultStart = convertDateISO(startDate.$d).split(
                    "T"
                  )[0];
                  const resultEnd = convertDateISO(endDate.$d).split("T")[0];

                  return {
                    scheme: item.id,
                    master: openBonusSchemes?.staffId,
                    network: item.network.id,
                    start_date: resultStart,
                    end_date: resultEnd,
                  };
                })
                .filter(Boolean);

              dispatch(clearErrorPost());
              dispatch(
                postBonusSchemesNetwork({
                  network_y_id: JSON.parse(sessionStorage.getItem("studio"))
                    ?.y_id,
                  schemes: schemesArray,
                })
              );
            }}
          >
            {error && displayErrors(error)}
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              {bonusSchemesList?.data?.results?.map((item) => {
                return (
                  <Col className={styles.colContainer} key={item.id}>
                    <Row justify={"space-between"} style={{ margin: 0 }}>
                      <Col>
                        <Checkbox
                          checked={selectedCheckboxes.includes(item.id)}
                          onChange={(evt) => handleCheckboxChange(item.id, evt)}
                        />
                        <span style={{ marginLeft: 10, fontWeight: 700 }}>
                          {item?.name}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      justify={"space-between"}
                      style={{ margin: 0, gap: 16 }}
                    >
                      <Col style={{ padding: 0, flex: 1 }}>
                        <Form.Item
                          name={`beginnings_${item.id}`}
                          style={{ margin: 0 }}
                          label="Дата начала"
                          rules={[
                            {
                              required: selectedCheckboxes.includes(item.id),
                              message: "Пожалуйста, выберите дату начала",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD.MM.YYYY"
                            style={{ width: "100%", borderRadius: 2 }}
                            placeholder="Выберите дату"
                            disabled={!selectedCheckboxes.includes(item.id)}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ padding: 0, flex: 1 }}>
                        <Form.Item
                          name={`completions_${item.id}`}
                          style={{ margin: 0 }}
                          label="Дата завершения"
                          rules={[
                            {
                              required: selectedCheckboxes.includes(item.id),
                              message: "Пожалуйста, выберите дату начала",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD.MM.YYYY"
                            style={{ width: "100%", borderRadius: 2 }}
                            placeholder="Выберите дату"
                            disabled={!selectedCheckboxes.includes(item.id)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </div>
            <Row gutter={24} style={{ marginTop: 15 }}>
              <Col span={12}>
                <Button
                  style={{ width: "100%", borderRadius: 2 }}
                  onClick={() => {
                    setOpenBonusSchemes({ open: false });
                    dispatch(clearErrorPost());
                    dispatch(clearSuccessBonusSchemesPost());
                  }}
                >
                  Отмена
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#76CEC9",
                  }}
                  htmlType="submit"
                  type="primary"
                  disabled={selectedCheckboxes.length === 0}
                >
                  Добавить
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
