import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Table, Spin } from "antd";
import { DatePickerPopoverMount } from "../../components/DatePickerPopoverMount";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { convertDateToISO861, convertToLocalDate } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData, getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import { getPlanning, clearError } from "../../store/planning";
import {
  getPlanningData,
  getPlanningLoading,
  getError,
  getOpenPlanningCreateSelector,
  getOpenPlanningEditSelector,
  getSuccessEdit,
  getSuccessCreate,
} from "../../store/planning/selectors";
import { CreatePlanningModal } from "../../components/CreatePlanningModal";
import { EditPlanningModal } from "../../components/EditPlanningModal";
import displayErrors from "../../utils/displayErrors";
import { columns } from "./utils";

export const PlanningPage = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getPlanningLoading);
  const errorStudio = useSelector(getErrorStudio);
  const planningData = useSelector(getPlanningData);
  const openPlanningCreate = useSelector(getOpenPlanningCreateSelector);
  const openPlanningEdit = useSelector(getOpenPlanningEditSelector);
  const openSuccessEdit = useSelector(getSuccessEdit);
  const openSuccessCreate = useSelector(getSuccessCreate);
  const [width, setWidth] = useState(window.innerWidth);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const openInfo = useSelector(getOpenInfoSelector);

  const fetchData = () => {
    let filters = false;

    if (dateAfterFilter) {
      filters = {
        mon_start_date: convertDateToISO861(dateAfterFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    dispatch(clearErrorStudio());
    dispatch(clearError());

    dispatch(
      getPlanning({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        date: filters,
      })
    );
  };

  useEffect(() => {
    if (studioData && dateAfterFilter) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    // dateAfterFilter,
    studioData,
    openSuccessEdit,
    openSuccessCreate,
  ]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const monthName = firstDayOfMonth.toLocaleString("ru-RU", {
      month: "long",
    });

    setDateAfterFilter(convertToLocalDate(firstDayOfMonth).split(" ")[0]);
    setViewDataFilter(monthName.charAt(0).toUpperCase() + monthName.slice(1));
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopoverMount
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            setViewDataFilter={setViewDataFilter}
            dateAfterFilter={dateAfterFilter}
            setDateAfterFilter={setDateAfterFilter}
            fetchData={fetchData}
          />
        </div>
      </>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              maxWidth: 750,
              gap: 14,
              marginBottom: 28,
              overflow: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Факт:
                </div>
                <div
                  style={{
                    backgroundColor: "#B7E4C8",
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.fact?.toLocaleString("ru-RU")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  План:
                </div>
                <div
                  style={{
                    backgroundColor: "#B7E4C8",
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.plan?.toLocaleString("ru-RU")}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Текущий:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.current_result_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.current_result
                    ? `${planningData.summary.current_result} %`
                    : "0 %"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Прогноз:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.forecast_result_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.forecast_result
                    ? `${planningData.summary.forecast_result} %`
                    : "0 %"}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                  }}
                >
                  До плана:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.for_plan_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.for_plan
                    ? planningData?.summary?.for_plan?.toLocaleString("ru-RU")
                    : 0}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                  }}
                >
                  По прогнозу:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.for_plan_forecast_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.for_plan_forecast
                    ? planningData?.summary?.for_plan_forecast?.toLocaleString(
                        "ru-RU"
                      )
                    : 0}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                ></div>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Обеспечено:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.exp_summ_final_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 102,
                    height: 22,
                    borderRadius: 5,
                    padding: "3px 0",
                  }}
                >
                  {planningData?.summary?.exp_summ_final
                    ? planningData?.summary?.exp_summ_final?.toLocaleString(
                        "ru-RU"
                      )
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginBottom: 10,
              fontSize: 12,
              fontWeight: 300,
              marginLeft: 5,
            }}
          >
            Показано {planningData?.data?.length || 0} из{" "}
            {planningData?.data?.length || 0}
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 290px)",
              width: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {(error || errorStudio) && displayErrors(error || errorStudio)}
            <Table
              columns={columns()}
              rowKey={(obj) => obj.id}
              size="small"
              pagination={false}
              className={`${styles.table} ${styles.table_header}`}
              dataSource={planningData?.data || []}
              rowClassName={(record) => {
                return record.weekend ? styles.rowTable : "";
              }}
              // onRow={() => ({
              //   onClick: (evt) => {
              //     evt.stopPropagation();
              //   },
              // })}
            />
          </div>
        </>
      )}
      {openPlanningCreate && (
        <CreatePlanningModal
          openPlanningCreate={openPlanningCreate}
          dateAfter={dateAfterFilter}
          dateBefore={planningData?.data?.[planningData.data.length - 1]?.day}
        />
      )}
      {openPlanningEdit && (
        <EditPlanningModal
          openPlanningEdit={openPlanningEdit}
          dateAfter={dateAfterFilter}
          dateBefore={planningData?.data?.[planningData.data.length - 1]?.day}
          plan={planningData?.summary}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по планированию"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
