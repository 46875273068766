import { useState, useEffect } from "react";
import { Table, Image, Row, Col, Spin, Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStaffNetworkDetail } from "../../store/staff";
import {
  getErrorEdit,
  getStaffsNetworkDataDetailSelector,
  getStaffLoading,
} from "../../store/staff/selectors";
import {
  deleteBonusSchemesNetwork,
  clearSuccessBonusSchemesDelete,
} from "../../store/awardSchemesMasterNetwork";
import {
  getSuccessDeleteBonusSchemes,
  getAwardSchemesLoadingDelete,
  getErrorBonusSchemesDelete,
  getSuccessPost,
} from "../../store/awardSchemesMasterNetwork/selectors";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import displayErrors from "../../utils/displayErrors";
import { formatDateString } from "../../utils/utils";
import styles from "./styles.module.css";
import { BonusSchemesNetworkModal } from "../../components/BonusSchemesNetworkModal";
import { columns } from "./utils";

export const StaffNetworkEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector(getStaffLoading);
  const error = useSelector(getErrorEdit);
  const loaderBonusSchemes = useSelector(getAwardSchemesLoadingDelete);
  const errorBonusSchemes = useSelector(getErrorBonusSchemesDelete);
  const successDeleteBonusSchemes = useSelector(getSuccessDeleteBonusSchemes);
  const successPostBonusSchemes = useSelector(getSuccessPost);
  const [width, setWidth] = useState(window.innerWidth);
  const staff = useSelector(getStaffsNetworkDataDetailSelector);
  const [staffId, setStaffId] = useState(null);
  const [openBonusSchemes, setOpenBonusSchemes] = useState(false);

  useEffect(() => {
    setStaffId(JSON.parse(sessionStorage.getItem("user"))?.id);
  }, []);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("studio")) && staffId) {
      dispatch(
        getStaffNetworkDetail({
          network_y_id: JSON.parse(sessionStorage.getItem("studio"))?.y_id,
          id: staffId,
        })
      );
    }
    dispatch(clearSuccessBonusSchemesDelete());
  }, [
    JSON.parse(sessionStorage.getItem("studio"))?.y_id,
    staffId,
    successDeleteBonusSchemes,
    successPostBonusSchemes,
  ]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 34,
        overflowY: "auto",
        margin: 0,
        height: "calc(100vh - 120px)",
        alignItems: width < 1210 && "center",
      }}
    >
      {error && displayErrors(error)}
      <Row
        gutter={16}
        style={{
          gap: 34,
          width: "100%",
          flexWrap: "wrap",
          justifyContent: width < 1210 ? "center" : "space-between",
        }}
      >
        <Col
          style={{
            padding: "19px 23px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            minHeight: 450,
            minWidth: 320,
          }}
        >
          <Image
            src={staff?.staff[0]?.y_avatar_big || staff?.staff[0]?.y_avatar}
            alt="avatar"
            style={{
              width: 193,
              borderRadius: "50%",
              height: 193,
            }}
          />
        </Col>
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minHeight: 450,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            minWidth: 320,
          }}
        >
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Имя
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {staff?.name}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Пользователь
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
                textWrap: "nowrap",
              }}
            >
              {staff?.staff[0]?.user?.external_data?.y_name} -{" "}
              {staff?.staff[0]?.user?.external_data?.y_phone}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Дата найма в сеть
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {formatDateString(staff?.hiring_date)}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Дата увольнения
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {formatDateString(staff?.dismissal_date)}
            </div>
          </div>
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Статус
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
              }}
            >
              {staff?.is_working}
            </div>
          </div>
        </Col>
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minHeight: 450,
            minWidth: 320,
          }}
        >
          <div>
            <p
              style={{
                marginTop: 0,
                marginBottom: 3,
                color: "#8B8B8B",
                fontSize: "10px",
              }}
            >
              Филиалы
            </p>
            <div
              style={{
                border: "1px solid #4B4A4A33",
                borderRadius: 2,
                padding: "10px 22px",
                backgroundColor: "#F9F9F9",
                minHeight: 131,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {staff?.studios?.map((item) => {
                return (
                  <p key={item?.id} style={{ margin: 0 }}>
                    {item?.y_title}
                  </p>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{
          width: "100%",
          gap: 34,
          justifyContent: width < 1210 ? "center" : "space-between",
        }}
      >
        <Col
          style={{
            padding: "19px 23px",
            backgroundColor: "#FFF",
            flex: 1,
            minWidth: 320,
            display: "flex",
            flexDirection: "column",
            minHeight: 467,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 14 }}>
            <Typography.Text style={{ fontSize: 16 }}>
              Схемы премирования
            </Typography.Text>

            {loaderBonusSchemes ? (
              <Spin size="large" />
            ) : staff?.network_master_bonusschemes?.length > 0 ? (
              <Row style={{ width: "100%", gap: 9 }}>
                {errorBonusSchemes && displayErrors(errorBonusSchemes)}
                {staff.network_master_bonusschemes.map((item) => {
                  return (
                    <Col
                      key={item.id}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        border: "1px #D5D5D5 solid",
                        padding: "12px 9px 12px 21px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 30,
                          fontWeight: 700,
                        }}
                      >
                        {item?.scheme?.name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>
                          с:
                          <span style={{ fontWeight: 700 }}>
                            {" " + item?.start_date}
                          </span>
                        </span>
                        <span>
                          по:
                          <span style={{ fontWeight: 700 }}>
                            {" " + item?.end_date}
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <DeleteOutlined
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "#8090B8",
                          }}
                          onClick={() =>
                            dispatch(
                              deleteBonusSchemesNetwork({
                                network_y_id: JSON.parse(
                                  sessionStorage.getItem("studio")
                                )?.y_id,
                                id: item.id,
                              })
                            )
                          }
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <span style={{ textAlign: "center" }}>
                Схемы премирования еще не добавлены
              </span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#76CEC9",
                width: 173,
                height: 36,
                borderRadius: 2,
              }}
              onClick={() => {
                setOpenBonusSchemes({ staffId: staffId, open: true });
              }}
            >
              Добавить схему
            </Button>
          </div>
        </Col>
        {width > 1175 && (
          <Col
            style={{
              flex: 1,
              minWidth: 320,
              padding: "19px 23px",
            }}
          ></Col>
        )}
        {width > 1310 && (
          <Col
            style={{
              flex: 1,
              minWidth: 320,
              padding: "19px 23px",
            }}
          ></Col>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {error && displayErrors(error)}
          <Table
            columns={columns()}
            rowKey={(obj) => obj.id}
            size="small"
            pagination={false}
            loading={loader && <Spin size="large" />}
            className={`${styles.table} ${styles.table_header}`}
            dataSource={staff?.staff || []}
            onRow={(record) => ({
              onClick: () => {
                sessionStorage.setItem("user", JSON.stringify(record));
                sessionStorage.setItem("studio", JSON.stringify(record.studio));
                navigate(`/staff_edit/${record?.studio?.y_id}`);
              },
            })}
          />
        </div>
      </Row>
      {openBonusSchemes && (
        <BonusSchemesNetworkModal
          openBonusSchemes={openBonusSchemes}
          setOpenBonusSchemes={setOpenBonusSchemes}
        />
      )}
    </div>
  );
};
