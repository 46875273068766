import { createColumn } from "../../utils/utils";
import { Space, Avatar, Checkbox } from "antd";

export const status = [
  { name: "Есть выплата", bol: true },
  { name: "Нет выплаты", bol: false },
];

export const columns = ({
  isOpenCalculationNetwork,
  calculationData,
  isCheckedAll,
  setIsCheckedAll,
  setCheckedRows,
  checkedRows,
  formatDateString,
  ordering,
}) => {
  return [
    createColumn(
      () => {
        const hasTrueRow = calculationData?.some(
          (row) => !row?.payment && row?.total > 0
        );

        return (
          isOpenCalculationNetwork &&
          hasTrueRow && (
            <Checkbox
              checked={isCheckedAll}
              onChange={(e) => {
                e.stopPropagation();
                setIsCheckedAll(e.target.checked);
                if (e.target.checked) {
                  const visibleRows = calculationData?.filter(
                    (row) => !row?.payment && row?.total > 0
                  );
                  setCheckedRows(visibleRows.map((row) => row));
                } else {
                  setCheckedRows([]);
                }
              }}
            />
          )
        );
      },
      "checkbox",
      (_, row) => {
        const isChecked = checkedRows.includes(row);

        return (
          isOpenCalculationNetwork &&
          !row?.payment &&
          row?.total > 0 && (
            <Checkbox
              checked={isChecked}
              onChange={(evt) => {
                evt.stopPropagation();
                let newCheckedRows;
                if (!isChecked) {
                  newCheckedRows = [...checkedRows, row];
                } else {
                  newCheckedRows = checkedRows.filter(
                    (item) => item?.employee?.id !== row?.employee?.id
                  );
                }
                setCheckedRows(newCheckedRows);

                const visibleRows = calculationData?.filter(
                  (row) => !row?.payment && row?.total > 0
                );
                const isAllVisibleChecked =
                  newCheckedRows.length === visibleRows.length;

                setIsCheckedAll(isAllVisibleChecked);
              }}
            />
          )
        );
      },
      30,
      "center"
    ),
    createColumn(
      "Mастер",
      "employee",
      (_, row) => {
        return (
          row?.employee && (
            <div style={{ display: "flex", flexDirection: "column", gap: 11 }}>
              <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                <Avatar
                  style={{ width: 25, minWidth: 25, height: 25 }}
                  src={row?.employee?.avatar}
                />
                <Space direction="vertical" style={{ gap: 0 }}>
                  <span style={{ textWrap: "nowrap" }}>
                    {row?.employee?.name}
                  </span>
                  <span
                    style={{
                      color: "#8B8B8B",
                      fontSize: 12,
                      lineHeight: "14.52px",
                      textWrap: "nowrap",
                    }}
                  >
                    {row?.employee?.position}
                  </span>
                </Space>
              </div>
            </div>
          )
        );
      },
      50
    ),
    createColumn(
      "Студия",
      "studio",
      (_, row) => {
        return (
          row?.master?.studio?.y_title || (
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                  color: "#8B8B8B",
                }}
              >
                Основная студия:
              </p>
              <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                {row?.studio}
              </p>
            </div>
          )
        );
      },
      150,
      undefined,
      ordering?.studio,
      () => {
        return 0;
      }
    ),
    createColumn(
      "Зарплата по Yclients",
      "y_client_salary_data",
      (_, row) => {
        return (
          (row?.salary || row?.y_client_salary_data) &&
          `${
            row?.salary?.toLocaleString("ru-RU") ||
            row?.y_client_salary_data?.toLocaleString("ru-RU")
          } р.`
        );
      },
      100
    ),
    createColumn(
      "Штраф",
      "total_penalty",
      (_, row) => {
        return (
          row?.total_penalty &&
          `${row?.total_penalty?.toLocaleString("ru-RU")} р.`
        );
      },
      100
    ),
    createColumn(
      "Бонус",
      "total_bonus",
      (_, row) => {
        return (
          row?.total_bonus && `${row?.total_bonus?.toLocaleString("ru-RU")} р.`
        );
      },
      100
    ),
    createColumn(
      "Премия",
      "total_bounty",
      (_, row) => {
        return row?.total_bounty && `${row?.total_bounty} р.`;
      },
      100
    ),
    createColumn(
      "Выплата до зарплаты",
      "total_prepayment",
      (_, row) => {
        return row?.total_prepayment && `${row?.total_prepayment} р.`;
      },
      100
    ),
    createColumn(
      "Официальная зарплата",
      "total_official_salary",
      (_, row) => {
        return (
          row?.total_official_salary &&
          `${row?.total_official_salary?.toLocaleString("ru-RU")} р.`
        );
      },
      100
    ),
    createColumn(
      "Выплаты",
      "payments",
      (_, row) => {
        if (row?.payments && row.payments.length > 0) {
          return (
            <Space direction="vertical" style={{ gap: 0 }}>
              {row.payments.map((item) => {
                return (
                  <Space
                    key={item.id}
                    direction="horizontal"
                    style={{ gap: 5 }}
                  >
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {formatDateString(item?.period_start_date)} -{" "}
                      {formatDateString(item?.period_end_date)}
                    </span>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        textWrap: "nowrap",
                      }}
                    >
                      {item?.paid?.toLocaleString("ru-RU")} р.
                    </span>
                  </Space>
                );
              })}
            </Space>
          );
        } else {
          return row?.payment ? (
            <p style={{ margin: 0 }}>Есть</p>
          ) : (
            <p style={{ margin: 0 }}>Нет</p>
          );
        }
      },
      150
    ),
    createColumn(
      "К выплате",
      "total_salary",
      (_, row) => {
        const color = row?.total < 0 || row?.total_salary < 0 ? "red" : "black";

        return (
          (row?.total_salary && (
            <div style={{ color: color }}>
              {row?.total_salary?.toLocaleString("ru-RU")} р.
            </div>
          )) ||
          (row?.total && (
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                  color: "#8B8B8B",
                }}
              >
                К выплате по сети:
              </p>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                  color: color,
                }}
              >
                {row?.total?.toLocaleString("ru-RU")} р.
              </p>
            </div>
          ))
        );
      },
      150
    ),
  ];
};
